<template>
  <div class="card card-custom card-transparent">
    <div class="card-body p-0">
      <div style="margin-bottom: 10px">
        <b-button variant="primary" @click="exportExcel('2022')"
          ><i class="fa fa-download" aria-hidden="true" />Export PM PPB Pemda
          (2022)
        </b-button>
        <b-button variant="primary" class="ml-1" @click="exportExcel('2023')"
          ><i class="fa fa-download" aria-hidden="true" />Export PM PPB Pemda
          (2023)
        </b-button>
        <b-button variant="primary" class="ml-1" @click="exportExcel('2024')"
          ><i class="fa fa-download" aria-hidden="true" />Export PM PPB Pemda
          (2024)
        </b-button>
      </div>
      <!--begin: Wizard-->
      <div
        class="wizard wizard-4"
        id="kt_wizard_v4"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav-->
        <div class="wizard-nav">
          <div class="wizard-steps">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-wrapper">
                <div class="wizard-number">1</div>
                <div class="wizard-label">
                  <div class="wizard-title">Penerapan Perizinan Berusaha</div>
                  <div class="wizard-desc">Penerapan Perizinan Berusaha</div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-number">2</div>
                <div class="wizard-label">
                  <div class="wizard-title">
                    Penyederhanaan Persyaratan Dasar Perizinan Berusaha
                  </div>
                  <div class="wizard-desc">
                    Penyederhanaan Persyaratan Dasar Perizinan Berusaha
                  </div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-number">3</div>
                <div class="wizard-label">
                  <div class="wizard-title">Peningkatan Iklim Investasi</div>
                  <div class="wizard-desc">Peningkatan Iklim Investasi</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body-->
        <div class="card card-custom card-shadowless rounded-top-0">
          <div class="card-body p-0">
            <div class="row justify-content-center py-12 px-0 py-lg-15 px-lg-0">
              <div class="col-xl-12 col-xxl-11">
                <!--begin: Wizard Form-->
                <form class="form mt-0 mt-lg-10" id="kt_form">
                  <!--begin: Wizard Step 1-->
                  <div
                    class="pb-5"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                  >
                    <div
                      style="background-color: #1bc5bd"
                      class="card card-custom"
                    >
                      <div v-if="pm.catatan_1_surveyor != null">
                        <b-alert show variant="danger"
                          >Terdapat catatan, silahkan lihat catatan
                          dibawah!</b-alert
                        >
                      </div>
                      <div class="card-body text-green">
                        <h3 class="card-label" style="color: white">
                          <i
                            class="fa fa-info-circle"
                            style="color: white"
                            aria-hidden="true"
                          ></i>
                          Penting!
                        </h3>
                        <hr />
                        <p style="color: white">
                          Input data yang berkaitan dengan Penerapan Perizinan
                          Berusaha berdasarkan fakta yang ada dengan memilih
                          data yang sesuai. Dokumen lampiran yang diunggah
                          adalah
                          <strong style="color: yellow">halaman depan</strong>
                          dan
                          <strong style="color: yellow"
                            >halaman pengesahan</strong
                          >
                          dokumen SOP, peraturan, dan lainnya. Ukuran file
                          setiap dokumen maksimal
                          <strong style="color: yellow">500KB</strong> dengan
                          format file
                          <strong style="color: yellow">*.pdf</strong>.
                        </p>
                      </div>
                    </div>
                    <br />
                    <div class="table-responsive-sm">
                      <table class="table">
                        <thead>
                          <tr class="table-active">
                            <th style="display: none">No</th>
                            <th style="display: none">Indikator</th>
                            <!-- <th style="display:none;">Status</th> -->
                            <th style="display: none">Jawaban</th>
                            <th style="display: none">Download</th>
                            <th style="display: none">Hasil</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="5" style="background-color: #ebedf3">
                              <b
                                >Penyusunan dan atau revisi peraturan daerah
                                atau peraturan kepala daerah</b
                              >
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="2">1</td>
                            <td rowspan="2">
                              Apakah ada Perda atau Perkada dalam dokumen
                              Prolegda ?
                            </td>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1a1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11" style="text-align: justify">
                                  Penyusunan Perda dan Perkada berdasarkan Pasal
                                  4 ayat (1), Pasal 5 ayat (1), dan Pasal 39
                                  ayat (1) PP No. 6 Tahun 2021 tentang
                                  Penyelenggaraan Perizinan Berusaha di Daerah,
                                  Pemda wajib menyesuaikan Perda dan Perkada
                                  paling lama 2 (dua) bulan terhitung sejak PP
                                  No. 6 Tahun 2021 diundangkan. Penilaian
                                  keberadaan Perkada dalam program pembentukan
                                  peraturan kepala daerah (Propemperkada)
                                  dilakukan dengan ketentuan Perkada tentang
                                  penyelenggaraan perizinan berusaha berbasis
                                  risiko terdapat dalam Propemperkada
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a1_file1)"
                                v-if="pm.p1a1_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p1a1_file1_status == 'Sesuai'"
                                v-model="pm.p1a1_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p1a1_file1_status == 'Tidak sesuai'
                                "
                                v-model="pm.p1a1_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p1a1_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada” apabila ketentuan di atas
                                    terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila ketentuan di
                                    atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="7">2</td>
                            <td rowspan="7">
                              Apakah ada Konsultasi pengusulan Perda dan Perkada
                              dengan OPD daerah?
                            </td>
                            <td colspan="3" style="text-align: justify">
                              Penyusunan Perda dan Perkada berdasarkan Pasal 4
                              ayat (1), Pasal 5 ayat (1), dan Pasal 39 ayat (1)
                              PP No. 6 Tahun 2021 tentang Penyelenggaraan
                              Perizinan Berusaha di Daerah, Pemda wajib
                              menyesuaikan Perda dan Perkada paling lama 2 (dua)
                              bulan terhitung sejak PP No. 6 Tahun 2021
                              diundangkan. Penilaian konsultasi pengusulan
                              Perkada dengan Organisasi Perangkat Daerah (OPD)
                              teknis dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1a2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Konsultasi dilakukan sekurang-kurangnya 1 kali
                                  dalam 3 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a2_file1)"
                                v-if="pm.p1a2_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p1a2_file1_status == 'Sesuai'"
                                v-model="pm.p1a2_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p1a2_file1_status == 'Tidak sesuai'
                                "
                                v-model="pm.p1a2_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p1a2_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1a2_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat surat undangan resmi (melibatkan
                                  pihak eksternal) dalam bentuk fisik atau
                                  digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a2_file2)"
                                v-if="pm.p1a2_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p1a2_file2_status == 'Sesuai'"
                                v-model="pm.p1a2_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p1a2_file2_status == 'Tidak sesuai'
                                "
                                v-model="pm.p1a2_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p1a2_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1a2_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a2_file3)"
                                v-if="pm.p1a2_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p1a2_file3_status == 'Sesuai'"
                                v-model="pm.p1a2_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p1a2_file3_status == 'Tidak sesuai'
                                "
                                v-model="pm.p1a2_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p1a2_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1a2_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a2_file4)"
                                v-if="pm.p1a2_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p1a2_file4_status == 'Sesuai'"
                                v-model="pm.p1a2_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p1a2_file4_status == 'Tidak sesuai'
                                "
                                v-model="pm.p1a2_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p1a2_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1a2_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a2_file5)"
                                v-if="pm.p1a2_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p1a2_file5_status == 'Sesuai'"
                                v-model="pm.p1a2_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p1a2_file5_status == 'Tidak sesuai'
                                "
                                v-model="pm.p1a2_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p1a2_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada 1 kali” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">3</td>
                            <td rowspan="7">
                              Apakah ada Konsultasi pengusulan Perda dan Perkada
                              dengan Kementerian Dalam Negeri, Kementerian
                              Investasi dan Kementerian Teknis lainnya?
                            </td>
                            <td colspan="3" style="text-align: justify">
                              Kementerian Investasi/BKPM dan Kementerian Teknis
                              lainnya dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1a3_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Konsultasi dilakukan sekurang-kurangnya 1 kali
                                  dalam 3 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a3_file1)"
                                v-if="pm.p1a3_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p1a3_file1_status == 'Sesuai'"
                                v-model="pm.p1a3_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p1a3_file1_status == 'Tidak sesuai'
                                "
                                v-model="pm.p1a3_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p1a3_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1a3_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat surat undangan resmi (melibatkan
                                  pihak eksternal) dalam bentuk fisik atau
                                  digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a3_file2)"
                                v-if="pm.p1a3_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p1a3_file2_status == 'Sesuai'"
                                v-model="pm.p1a3_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p1a3_file2_status == 'Tidak sesuai'
                                "
                                v-model="pm.p1a3_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p1a3_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1a3_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a3_file3)"
                                v-if="pm.p1a3_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p1a3_file3_status == 'Sesuai'"
                                v-model="pm.p1a3_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p1a3_file3_status == 'Tidak sesuai'
                                "
                                v-model="pm.p1a3_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p1a3_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1a3_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a3_file4)"
                                v-if="pm.p1a3_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p1a3_file4_status == 'Sesuai'"
                                v-model="pm.p1a3_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p1a3_file4_status == 'Tidak sesuai'
                                "
                                v-model="pm.p1a3_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p1a3_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1a3_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                  digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a3_file5)"
                                v-if="pm.p1a3_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p1a3_file5_status == 'Sesuai'"
                                v-model="pm.p1a3_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p1a3_file5_status == 'Tidak sesuai'
                                "
                                v-model="pm.p1a3_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p1a3_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada 1 kali” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">4</td>
                            <td rowspan="7">
                              Apakah ada Koordinasi pengusulan Perda dan Perkada
                              dengan Kementerian Dalam Negeri, Kementerian
                              Investasi dan Kementerian Teknis lainnya?
                            </td>
                            <td colspan="3" style="text-align: justify">
                              Penyusunan Perda dan Perkada berdasarkan Pasal 4
                              ayat (1), Pasal 5 ayat (1), dan Pasal 39 ayat (1)
                              PP No. 6 Tahun 2021 tentang Penyelenggaraan
                              Perizinan Berusaha di Daerah, Pemda wajib
                              menyesuaikan Perda dan Perkada paling lama 2 (dua)
                              bulan terhitung sejak PP No. 6 Tahun 2021
                              diundangkan. Penilaian koordinasi pengusulan
                              Perkada dengan Kementerian Dalam Negeri,
                              Kementerian Investasi/BKPM dan Kementerian Teknis
                              lainnya dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1a4_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Koordinasi dilakukan sekurang-kurangnya 1 kali
                                  dalam 3 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a4_file1)"
                                v-if="pm.p1a4_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p1a4_file1_status == 'Sesuai'"
                                v-model="pm.p1a4_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p1a4_file1_status == 'Tidak sesuai'
                                "
                                v-model="pm.p1a4_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p1a4_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1a4_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat surat undangan resmi (melibatkan
                                  pihak eksternal) dalam bentuk fisik atau
                                  digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a4_file2)"
                                v-if="pm.p1a4_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p1a4_file2_status == 'Sesuai'"
                                v-model="pm.p1a4_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p1a4_file2_status == 'Tidak sesuai'
                                "
                                v-model="pm.p1a4_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p1a4_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1a4_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digita
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a4_file3)"
                                v-if="pm.p1a4_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p1a4_file3_status == 'Sesuai'"
                                v-model="pm.p1a4_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p1a4_file3_status == 'Tidak sesuai'
                                "
                                v-model="pm.p1a4_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p1a4_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1a4_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a4_file4)"
                                v-if="pm.p1a4_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p1a4_file4_status == 'Sesuai'"
                                v-model="pm.p1a4_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p1a4_file4_status == 'Tidak sesuai'
                                "
                                v-model="pm.p1a4_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p1a4_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1a4_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                  digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a4_file5)"
                                v-if="pm.p1a4_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p1a4_file5_status == 'Sesuai'"
                                v-model="pm.p1a4_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p1a4_file5_status == 'Tidak sesuai'
                                "
                                v-model="pm.p1a4_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p1a4_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada 1 kali” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="2">5</td>
                            <td rowspan="2">
                              Apakah ada dokumen Peraturan daerah atau Peraturan
                              Kepala Daerah terkait penerapan perizinan berusaha
                              berbasis risiko?
                            </td>
                            <td>
                              <b-form-group v-slot="{ ariaDescribedby }">
                                <b-form-radio
                                  disabled
                                  v-model="pm.p1a5_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="some-radios"
                                  value="25"
                                  >Ada, sudah disahkan</b-form-radio
                                >
                                <b-form-radio
                                  disabled
                                  v-model="pm.p1a5_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="some-radios"
                                  value="10"
                                  >Ada, belum disahkan</b-form-radio
                                >
                                <b-form-radio
                                  disabled
                                  v-model="pm.p1a5_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="some-radios"
                                  value="0"
                                  >Belum ada</b-form-radio
                                >
                              </b-form-group>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a5_file1)"
                                v-if="pm.p1a5_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p1a5_nilai_status == 'Sesuai'"
                                v-model="pm.p1a5_nilai_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p1a5_nilai_status == 'Tidak sesuai'
                                "
                                v-model="pm.p1a5_nilai_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p1a5_nilai_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, sudah disahkan” apabila
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, belum disahkan” apabila
                                    ketentuan di atas tidak terpenuhi namun
                                    sudah tersedia dalam bentuk naskah/dokumen
                                    usulan. <br />
                                    3. Nilai “Belum ada” apabila ketentuan di
                                    atas tidak terpenuhi
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="7">6</td>
                            <td rowspan="7">
                              Apakah ada kegiatan Sosialisasi Perda atau Perkada
                              terkait penerapam perizinan berusaha berbasis
                              risiko?
                            </td>
                            <td colspan="3" style="text-align: justify">
                              Penyusunan Perda dan Perkada berdasarkan Pasal 4
                              ayat (1), Pasal 5 ayat (1), dan Pasal 39 ayat (1)
                              PP No. 6 Tahun 2021 tentang Penyelenggaraan
                              Perizinan Berusaha di Daerah, Pemda wajib
                              menyesuaikan Perda dan Perkada paling lama 2 (dua)
                              bulan terhitung sejak PP No. 6 Tahun 2021
                              diundangkan. Penilaian ada tidaknya sosialisasi
                              Perkada terkait penerapan perizinan berusaha
                              berbasis risiko dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1a6_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Sosialisasi dilakukan sekurang-kurangnya 1
                                  kali dalam 3 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a6_file1)"
                                v-if="pm.p1a6_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p1a6_file1_status == 'Sesuai'"
                                v-model="pm.p1a6_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p1a6_file1_status == 'Tidak sesuai'
                                "
                                v-model="pm.p1a6_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p1a6_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1a6_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a6_file2)"
                                v-if="pm.p1a6_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p1a6_file2_status == 'Sesuai'"
                                v-model="pm.p1a6_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p1a6_file2_status == 'Tidak sesuai'
                                "
                                v-model="pm.p1a6_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p1a6_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1a6_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a6_file3)"
                                v-if="pm.p1a6_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p1a6_file3_status == 'Sesuai'"
                                v-model="pm.p1a6_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p1a6_file3_status == 'Tidak sesuai'
                                "
                                v-model="pm.p1a6_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p1a6_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1a6_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat notula sosialisasi
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a6_file4)"
                                v-if="pm.p1a6_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p1a6_file4_status == 'Sesuai'"
                                v-model="pm.p1a6_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p1a6_file4_status == 'Tidak sesuai'
                                "
                                v-model="pm.p1a6_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p1a6_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1a6_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a6_file5)"
                                v-if="pm.p1a6_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p1a6_file5_status == 'Sesuai'"
                                v-model="pm.p1a6_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p1a6_file5_status == 'Tidak sesuai'
                                "
                                v-model="pm.p1a6_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p1a6_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, hanya 1 kali” apabila salah
                                    satu dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="5" style="background-color: #ebedf3">
                              <b>Verifikasi</b>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="3">1</td>
                            <td rowspan="3">
                              Berapa persen verifikasi teknis dan notifikasi
                              pemenuhan standar dan persyaratan Perizinan
                              Berusaha Berbasis Risiko?
                            </td>
                            <td rowspan="2">
                              <b-form-group v-slot="{ ariaDescribedby }">
                                <b-form-radio
                                  disabled
                                  v-model="pm.p1b1_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="pm.p1b1_nilai"
                                  value="25"
                                  >85 – 100%</b-form-radio
                                >
                                <b-form-radio
                                  disabled
                                  v-model="pm.p1b1_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="pm.p1b1_nilai"
                                  value="15"
                                  >70 – 84,99%</b-form-radio
                                >
                                <b-form-radio
                                  disabled
                                  v-model="pm.p1b1_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="pm.p1b1_nilai"
                                  value="10"
                                >
                                  50 – 69,99%</b-form-radio
                                >
                                <b-form-radio
                                  disabled
                                  v-model="pm.p1b1_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="pm.p1b1_nilai"
                                  value="0"
                                  >Di bawah 50%</b-form-radio
                                >
                              </b-form-group>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1b1_file1)"
                                v-if="pm.p1b1_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p1b1_nilai_status == 'Sesuai'"
                                v-model="pm.p1b1_nilai_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p1b1_nilai_status == 'Tidak sesuai'
                                "
                                v-model="pm.p1b1_nilai_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p1b1_nilai_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr colspan="2">
                            <td>
                              <button
                                @click="unduhFile(pm.p1b1_file2)"
                                v-if="pm.p1b1_file2 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div
                                  class="card-body text-green"
                                  style="color: white; text-align: justify"
                                >
                                  <p>Informasi</p>
                                  <p>
                                    Penilaian verifikasi teknis dan notifikasi
                                    pemenuhan standar dan persyaratan Perizinan
                                    Berusaha Berbasis Risiko dilakukan dengan
                                    ketentuan:
                                  </p>

                                  <ol style="margin-left: 11px">
                                    <li>
                                      Verifikasi teknis dan notifikasi pemenuhan
                                      standar oleh pemerintah daerah terhadap
                                      permohonan perizinan berusaha dengan
                                      tingkat risiko Menengah Tinggi (MT) yang
                                      menjadi kewenangannya.
                                    </li>
                                    <li>
                                      Verifikasi teknis dan notifikasi pemenuhan
                                      persyaratan oleh pemerintah daerah
                                      terhadap permohonan perizinan berusaha
                                      dengan tingkat risiko Tinggi (T) yang
                                      menjadi kewenangannya.
                                    </li>
                                    <li>
                                      Penilaian verifikasi teknis dan notifikasi
                                      pemenuhan standar dan persyaratan
                                      Perizinan Berusaha Berbasis Risiko
                                      dilakukan dengan menghitung perbandingan
                                      jumlah permohonan perizinan berusaha
                                      dengan tingkat risiko MT dan T yang telah
                                      diverifikasi dengan total permohonan
                                      perizinan berusaha yang masuk ke dalam
                                      dashboard OSS Pemda
                                    </li>
                                  </ol>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="5" style="background-color: #ebedf3">
                              <b>Monitoring dan Evaluasi</b>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">1</td>
                            <td rowspan="7">
                              Apakah ada monitoring dan evaluasi implementasi
                              penyusunan Perda/Perkada?
                            </td>
                            <td colspan="3" style="text-align: justify">
                              Penyusunan Perda dan Perkada berdasarkan Pasal 4
                              ayat (1), Pasal 5 ayat (1), dan Pasal 39 ayat (1)
                              PP No. 6 Tahun 2021 tentang Penyelenggaraan
                              Perizinan Berusaha di Daerah, Pemda wajib
                              menyesuaikan Perda dan Perkada paling lama 2 (dua)
                              bulan terhitung sejak PP No. 6 Tahun 2021
                              diundangkan. Penilaian ada tidaknya monitoring dan
                              evaluasi implementasi penyusunan perkada dilakukan
                              dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1c1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11" style="text-align: justify">
                                  Kegiatan monitoring dan evaluasi dilakukan
                                  sekurang-kurangnya 1 kali dalam 3 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1c1_file1)"
                                v-if="pm.p1c1_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p1c1_file1_status == 'Sesuai'"
                                v-model="pm.p1c1_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p1c1_file1_status == 'Tidak sesuai'
                                "
                                v-model="pm.p1c1_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p1c1_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1c1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11" style="text-align: justify">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1c1_file2)"
                                v-if="pm.p1c1_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p1c1_file2_status == 'Sesuai'"
                                v-model="pm.p1c1_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p1c1_file2_status == 'Tidak sesuai'
                                "
                                v-model="pm.p1c1_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p1c1_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1c1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11" style="text-align: justify">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1c1_file3)"
                                v-if="pm.p1c1_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p1c1_file3_status == 'Sesuai'"
                                v-model="pm.p1c1_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p1c1_file3_status == 'Tidak sesuai'
                                "
                                v-model="pm.p1c1_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p1c1_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1c1_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11" style="text-align: justify">
                                  Terdapat notula kegiatan monitoring dan
                                  evaluasi
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1c1_file4)"
                                v-if="pm.p1c1_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p1c1_file4_status == 'Sesuai'"
                                v-model="pm.p1c1_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p1c1_file4_status == 'Tidak sesuai'
                                "
                                v-model="pm.p1c1_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p1c1_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1c1_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11" style="text-align: justify">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1c1_file5)"
                                v-if="pm.p1c1_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p1c1_file5_status == 'Sesuai'"
                                v-model="pm.p1c1_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p1c1_file5_status == 'Tidak sesuai'
                                "
                                v-model="pm.p1c1_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p1c1_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, teragenda” apabila kelima
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, tidak teragenda” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi. <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="2">2</td>
                            <td rowspan="2">
                              Apakah ada laporan kinerja implementasi OSS ?
                            </td>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1c2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11" style="text-align: justify">
                                  Penilaian ada tidaknya laporan kinerja
                                  implementasi OSS dilakukan dengan ketentuan
                                  apabila laporan kinerja implementasi OSS
                                  dibuat secara formal dalam bentuk dokumen
                                  laporan lengkap fisik atau digital, bukan
                                  bahan presentasi dan sejenisnya
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1c2_file1)"
                                v-if="pm.p1c2_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p1c2_file1_status == 'Sesuai'"
                                v-model="pm.p1c2_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p1c2_file1_status == 'Tidak sesuai'
                                "
                                v-model="pm.p1c2_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p1c2_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada” apabila ketentuan di atas
                                    terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila ketentuan di
                                    atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div v-if="pm.catatan_1_surveyor != null">
                        <div class="card text-white bg-primary mb-3">
                          <!-- <div class="card-header">Header</div> -->
                          <div class="card-body">
                            <h5 class="card-title">
                              <i
                                class="fa fa-info-circle"
                                style="color: white"
                                aria-hidden="true"
                              ></i>
                              Catatan
                            </h5>
                            <hr />
                            <div
                              class="card-text"
                              v-html="pm.catatan_1_surveyor"
                            ></div>
                            <!-- <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end: Wizard Step 1-->

                  <!--begin: Wizard Step 2-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <div
                      style="background-color: #1bc5bd"
                      class="card card-custom"
                    >
                      <div v-if="pm.catatan_2_surveyor != null">
                        <b-alert show variant="danger"
                          >Terdapat catatan, silahkan lihat catatan
                          dibawah!</b-alert
                        >
                      </div>
                      <div class="card-body text-green">
                        <h3 class="card-label" style="color: white">
                          <i
                            class="fa fa-info-circle"
                            style="color: white"
                            aria-hidden="true"
                          ></i>
                          Penting!
                        </h3>
                        <hr />
                        <p style="color: white">
                          Input data yang berkaitan dengan Penyederhanaan
                          Persyaratan Dasar Perizinan Berusaha berdasarkan fakta
                          yang ada dengan memilih data yang sesuai. Dokumen
                          lampiran yang diunggah adalah
                          <strong style="color: yellow">halaman depan</strong>
                          dan
                          <strong style="color: yellow"
                            >halaman pengesahan</strong
                          >
                          dokumen SOP, peraturan, dan lainnya. Ukuran file
                          setiap dokumen maksimal
                          <strong style="color: yellow">500KB</strong> dengan
                          format file
                          <strong style="color: yellow">*.pdf</strong>.
                        </p>
                      </div>
                    </div>
                    <br />
                    <div class="table-responsive-sm">
                      <table class="table">
                        <thead>
                          <tr class="table-active">
                            <th style="display: none">No</th>
                            <th style="display: none">Indikator</th>
                            <!-- <th style="display:none;">Status</th> -->
                            <th style="display: none">Jawaban</th>
                            <th style="display: none">Download</th>
                            <th style="display: none">Hasil</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="5" style="background-color: #ebedf3">
                              <b
                                >Peraturan daerah atau peraturan kepala daerah
                                terkait penyusunan Rencana Detil Tata Ruang
                                Digital</b
                              >
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="4">1</td>
                            <td rowspan="4">
                              Apakah ada Perda atau Perkada dalam dokumen
                              Prolegda?
                            </td>
                            <td colspan="3" style="text-align: justify">
                              Penilaian Keberadaan Perda dalam dokumen program
                              pembentukan peraturan daerah (Propemperda) dan
                              Perkada dalam program pembentukan peraturan kepala
                              daerah (Propemperkada) dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2a1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Perda mengenai Rencana Tata Ruang Wilayah
                                  (RTRW)
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2a1_file1)"
                                v-if="pm.p2a1_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2a1_file1_status == 'Sesuai'"
                                v-model="pm.p2a1_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2a1_file1_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2a1_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2a1_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2a1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Perkada mengenai Rencana Detail Tata Ruang
                                  (RDTR)
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2a1_file2)"
                                v-if="pm.p2a1_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2a1_file2_status == 'Sesuai'"
                                v-model="pm.p2a1_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2a1_file2_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2a1_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2a1_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div
                                  class="card-body text-green"
                                  style="color: white; text-align: justify"
                                >
                                  <p style="color: white">Informasi</p>
                                  <p>
                                    Penyusunan Perda dan Perkada berdasarkan
                                    Pasal 32 ayat (2), dan Pasal 39 ayat (1) PP
                                    No. 6 Tahun 2021 tentang Penyelenggaraan
                                    Perizinan Berusaha di Daerah, Pemda wajib
                                    menyesuaikan Perda dan Perkada paling lama 2
                                    (dua) bulan terhitung sejak PP No. 6 Tahun
                                    2021 diundangkan. Perda dan Perkada dimaksud
                                    adalah:
                                  </p>

                                  <ol style="margin-left: 11px">
                                    <li>
                                      Perda mengenai Rencana Tata Ruang Wilayah
                                      (RTRW)
                                    </li>
                                    <li>
                                      Perkada mengenai Rencana Detail Tata Ruang
                                      (RDTR)
                                    </li>
                                  </ol>

                                  <p>Untuk penetapan bobot penilaian:</p>

                                  <ol style="margin-left: 11px">
                                    <li>
                                      Untuk pemerintah provinsi, nilai
                                      &ldquo;Ada&rdquo; apabila dapat
                                      menunjukkan naskah/dokumen usulan Perda
                                      mengenai Rencana Tata Ruang Wilayah (RTRW)
                                      dalam dokumen Propemperda.
                                    </li>
                                    <li>
                                      Untuk pemerintah kabupaten/kota, nilai
                                      &ldquo;Ada&rdquo; apabila dapat
                                      menunjukkan naskah/dokumen usulan Perkada
                                      mengenai Rencana Detail Tata Ruang (RDTR)
                                      dalam dokumen Propemperkada.
                                    </li>
                                    <li>
                                      Nilai &ldquo;Tidak ada&rdquo; apabila
                                      pemerintah provinsi tidak dapat memenuhi
                                      ketentuan nomor 1 dan pemerintah
                                      kabupaten/kota tidak dapat memenuhi
                                      ketentuan nomor 1 dan 2.
                                    </li>
                                  </ol>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">2</td>
                            <td rowspan="7">
                              Apakah ada Konsultasi pengusulan Perda dan Perkada
                              dengan OPD daerah?
                            </td>
                            <td colspan="3" style="text-align: justify">
                              Penilaian konsultasi pengusulan Perda dan Perkada
                              dengan Organisasi Perangkat Daerah (OPD) dilakukan
                              dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2a2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Konsultasi dilakukan sekurang-kurangnya 1 kali
                                  dalam 3 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2a2_file1)"
                                v-if="pm.p2a2_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2a2_file1_status == 'Sesuai'"
                                v-model="pm.p2a2_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2a2_file1_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2a2_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2a2_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2a2_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat surat undangan resmi (melibatkan
                                  pihak eksternal) dalam bentuk fisik atau
                                  digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2a2_file2)"
                                v-if="pm.p2a2_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2a2_file2_status == 'Sesuai'"
                                v-model="pm.p2a2_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2a2_file2_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2a2_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2a2_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2a2_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2a2_file3)"
                                v-if="pm.p2a2_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2a2_file3_status == 'Sesuai'"
                                v-model="pm.p2a2_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2a2_file3_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2a2_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2a2_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2a2_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2a2_file4)"
                                v-if="pm.p2a2_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2a2_file4_status == 'Sesuai'"
                                v-model="pm.p2a2_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2a2_file4_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2a2_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2a2_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2a2_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2a2_file5)"
                                v-if="pm.p2a2_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2a2_file5_status == 'Sesuai'"
                                v-model="pm.p2a2_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2a2_file5_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2a2_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2a2_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div
                                  class="card-body text-green"
                                  style="color: white; text-align: justify"
                                >
                                  <p style="color: white">Informasi</p>
                                  <p>
                                    Penyusunan Perda dan Perkada berdasarkan
                                    Pasal 32 ayat (2), dan Pasal 39 ayat (1) PP
                                    No. 6 Tahun 2021 tentang Penyelenggaraan
                                    Perizinan Berusaha di Daerah, Pemda wajib
                                    menyesuaikan Perda dan Perkada paling lama 2
                                    (dua) bulan terhitung sejak PP No. 6 Tahun
                                    2021 diundangkan. Perda dan Perkada dimaksud
                                    adalah:
                                  </p>

                                  <ol style="margin-left: 11px">
                                    <li>
                                      Perda mengenai Rencana Tata Ruang Wilayah
                                      (RTRW)
                                    </li>
                                    <li>
                                      Perkada mengenai Rencana Detail Tata Ruang
                                      (RDTR)
                                    </li>
                                  </ol>

                                  <p>Untuk penetapan bobot penilaian:</p>

                                  <ol style="margin-left: 11px">
                                    <li>
                                      Nilai &ldquo;Ada, lebih dari 1 kali&rdquo;
                                      apabila kelima ketentuan di atas
                                      terpenuhi.
                                    </li>
                                    <li>
                                      Nilai &ldquo;Ada 1 kali&rdquo; apabila
                                      salah satu dari ketentuan di atas tidak
                                      terpenuhi.
                                    </li>
                                    <li>
                                      Nilai &ldquo;Tidak ada&rdquo; apabila
                                      seluruh ketentuan di atas tidak terpenuhi.
                                    </li>
                                  </ol>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">3</td>
                            <td rowspan="7">
                              Apakah ada Konsultasi pengusulan Perda dan Perkada
                              dengan Kementerian Dalam Negeri, Kementerian
                              Investasi, dan Kementerian Teknis lainnya?
                            </td>
                            <td colspan="3" style="text-align: justify">
                              Penilaian konsultasi pengusulan Perda dan Perkada
                              dengan Kementerian Dalam Negeri, Kementerian
                              Investasi/BKPM dan Kementerian Teknis lainnya yang
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2a3_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Koordinasi dilakukan sekurang-kurangnya 1 kali
                                  dalam 3 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2a3_file1)"
                                v-if="pm.p2a3_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2a3_file1_status == 'Sesuai'"
                                v-model="pm.p2a3_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2a3_file1_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2a3_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2a3_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2a3_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2a3_file2)"
                                v-if="pm.p2a3_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2a3_file2_status == 'Sesuai'"
                                v-model="pm.p2a3_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2a3_file2_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2a3_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2a3_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2a3_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2a3_file3)"
                                v-if="pm.p2a3_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2a3_file3_status == 'Sesuai'"
                                v-model="pm.p2a3_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2a3_file3_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2a3_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2a3_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2a3_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2a3_file4)"
                                v-if="pm.p2a3_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2a3_file4_status == 'Sesuai'"
                                v-model="pm.p2a3_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2a3_file4_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2a3_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2a3_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2a3_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                  digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2a3_file5)"
                                v-if="pm.p2a3_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2a3_file5_status == 'Sesuai'"
                                v-model="pm.p2a3_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2a3_file5_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2a3_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2a3_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div
                                  class="card-body text-green"
                                  style="color: white"
                                >
                                  <p style="color: white">Informasi</p>
                                  <p style="text-align: justify">
                                    Penyusunan Perda dan Perkada berdasarkan
                                    Pasal 32 ayat (2), dan Pasal 39 ayat (1) PP
                                    No. 6 Tahun 2021 tentang Penyelenggaraan
                                    Perizinan Berusaha di Daerah, Pemda wajib
                                    menyesuaikan Perda dan Perkada paling lama 2
                                    (dua) bulan terhitung sejak PP No. 6 Tahun
                                    2021 diundangkan. Perda dan Perkada dimaksud
                                    adalah:
                                  </p>

                                  <ol style="margin-left: 11px">
                                    <li>
                                      Perda mengenai Rencana Tata Ruang Wilayah
                                      (RTRW)
                                    </li>
                                    <li>
                                      Perkada mengenai Rencana Detail Tata Ruang
                                      (RDTR)
                                    </li>
                                  </ol>

                                  <p>Untuk penetapan bobot penilaian:</p>

                                  <ol style="margin-left: 11px">
                                    <li>
                                      Nilai &ldquo;Ada, lebih dari 1 kali&rdquo;
                                      apabila kelima ketentuan di atas
                                      terpenuhi.
                                    </li>
                                    <li>
                                      Nilai &ldquo;Ada 1 kali&rdquo; apabila
                                      salah satu dari ketentuan di atas tidak
                                      terpenuhi.
                                    </li>
                                    <li>
                                      Nilai &ldquo;Tidak ada&rdquo; apabila
                                      seluruh ketentuan di atas tidak terpenuhi.
                                    </li>
                                  </ol>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">4</td>
                            <td rowspan="7">
                              Apakah ada koordinasi pengusulan Perda dan Perkada
                              dengan Kementerian Dalam Negeri, Kementerian
                              Investasi, dan Kementerian Teknis lainnya?
                            </td>
                            <td colspan="3" style="text-align: justify">
                              Penilaian koordinasi pengusulan Perda dan Perkada
                              dengan Kementerian Dalam Negeri, Kementerian
                              Investasi/BKPM dan Kementerian Teknis lainnya
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2a4_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Koordinasi dilakukan sekurang-kurangnya 1 kali
                                  dalam 3 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2a4_file1)"
                                v-if="pm.p2a4_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2a4_file1_status == 'Sesuai'"
                                v-model="pm.p2a4_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2a4_file1_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2a4_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2a4_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2a4_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat surat undangan resmi (melibatkan
                                  pihak eksternal) dalam bentuk fisik atau
                                  digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2a4_file2)"
                                v-if="pm.p2a4_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2a4_file2_status == 'Sesuai'"
                                v-model="pm.p2a4_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2a4_file2_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2a4_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2a4_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2a4_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2a4_file3)"
                                v-if="pm.p2a4_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2a4_file3_status == 'Sesuai'"
                                v-model="pm.p2a4_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2a4_file3_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2a4_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2a4_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2a4_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2a4_file4)"
                                v-if="pm.p2a4_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2a4_file4_status == 'Sesuai'"
                                v-model="pm.p2a4_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2a4_file4_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2a4_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2a4_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2a4_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                  digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2a4_file5)"
                                v-if="pm.p2a4_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2a4_file5_status == 'Sesuai'"
                                v-model="pm.p2a4_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2a4_file5_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2a4_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2a4_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div
                                  class="card-body text-green"
                                  style="color: white"
                                >
                                  <p style="color: white">Informasi</p>
                                  <p style="text-align: justify">
                                    Penyusunan Perda dan Perkada berdasarkan
                                    Pasal 32 ayat (2), dan Pasal 39 ayat (1) PP
                                    No. 6 Tahun 2021 tentang Penyelenggaraan
                                    Perizinan Berusaha di Daerah, Pemda wajib
                                    menyesuaikan Perda dan Perkada paling lama 2
                                    (dua) bulan terhitung sejak PP No. 6 Tahun
                                    2021 diundangkan. Perda dan Perkada dimaksud
                                    adalah:
                                  </p>

                                  <ol style="margin-left: 11px">
                                    <li>
                                      Perda mengenai Rencana Tata Ruang Wilayah
                                      (RTRW)
                                    </li>
                                    <li>
                                      Perkada mengenai Rencana Detail Tata Ruang
                                      (RDTR)
                                    </li>
                                  </ol>

                                  <p>Untuk penetapan bobot penilaian:</p>

                                  <ol style="margin-left: 11px">
                                    <li>
                                      Nilai &ldquo;Ada, lebih dari 1 kali&rdquo;
                                      apabila kelima ketentuan di atas
                                      terpenuhi.
                                    </li>
                                    <li>
                                      Nilai &ldquo;Ada 1 kali&rdquo; apabila
                                      salah satu dari ketentuan di atas tidak
                                      terpenuhi.
                                    </li>
                                    <li>
                                      Nilai &ldquo;Tidak ada&rdquo; apabila
                                      seluruh ketentuan di atas tidak terpenuhi.
                                    </li>
                                  </ol>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="3">5</td>
                            <td rowspan="3">
                              Apakah ada dokumen Peraturan Daerah atau Peraturan
                              Kepala Daerah terkait penyusunan RDTR Digital?
                            </td>
                            <td rowspan="2">
                              <b-form-group v-slot="{ ariaDescribedby }">
                                <b-form-radio
                                  disabled
                                  v-model="pm.p2a5_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p2a5_nilai"
                                  value="25"
                                  >Ada, sudah disahkan</b-form-radio
                                >
                                <b-form-radio
                                  disabled
                                  v-model="pm.p2a5_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p2a5_nilai"
                                  value="10"
                                  >Ada, belum disahkan</b-form-radio
                                >
                                <b-form-radio
                                  disabled
                                  v-model="pm.p2a5_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p2a5_nilai"
                                  value="0"
                                  >Belum ada</b-form-radio
                                >
                              </b-form-group>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2a5_file1)"
                                v-if="pm.p2a5_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2a5_nilai_status == 'Sesuai'"
                                v-model="pm.p2a5_nilai_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2a5_nilai_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2a5_nilai_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2a5_nilai_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr colspan="2">
                            <td>
                              <button
                                @click="unduhFile(pm.p2a5_file2)"
                                v-if="pm.p2a5_file2 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div
                                  class="card-body text-green"
                                  style="color: white; text-align: justify"
                                >
                                  <p style="color: white">Informasi</p>
                                  <p>
                                    Penyusunan Perda dan Perkada berdasarkan
                                    Pasal 32 ayat (2), dan Pasal 39 ayat (1) PP
                                    No. 6 Tahun 2021 tentang Penyelenggaraan
                                    Perizinan Berusaha di Daerah, Pemda wajib
                                    menyesuaikan Perda dan Perkada paling lama 2
                                    (dua) bulan terhitung sejak PP No. 6 Tahun
                                    2021 diundangkan. Perda dan Perkada dimaksud
                                    adalah
                                  </p>

                                  <ol style="margin-left: 11px">
                                    <li>
                                      Perda mengenai Rencana Tata Ruang Wilayah
                                      (RTRW)
                                    </li>
                                    <li>
                                      Perkada mengenai Rencana Detail Tata Ruang
                                      (RDTR)
                                    </li>
                                  </ol>

                                  <p>Untuk penetapan bobot penilaian:</p>

                                  <ol style="margin-left: 11px">
                                    <li>
                                      Nilai &ldquo;Ada, sudah disahkan&rdquo;
                                      apabila salah satu ketentuan di atas
                                      terpenuhi.
                                    </li>
                                    <li>
                                      Nilai &ldquo;Ada, belum disahkan&rdquo;
                                      apabila seluruh ketentuan di atas tidak
                                      terpenuhi namun sudah tersedia dalam
                                      bentuk naskah/dokumen usulan.
                                    </li>
                                    <li>
                                      Nilai &ldquo;Belum ada&rdquo; apabila
                                      seluruh ketentuan di atas tidak terpenuhi.
                                    </li>
                                  </ol>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="8">6</td>
                            <td rowspan="8">
                              Apakah ada kegiatan Sosialisasi Perda atau Perkada
                              terkait penyusunan RDTR Digital?
                            </td>
                            <td colspan="3" style="text-align: justify">
                              Penilaian ada tidaknya sosialisasi Perda atau
                              Perkada terkait penyusunan RDTR dilakukan dengan
                              ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2a6_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Sosialisasi dilakukan sekurang-kurangnya 1
                                  kali dalam 3 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2a6_file1)"
                                v-if="pm.p2a6_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2a6_file1_status == 'Sesuai'"
                                v-model="pm.p2a6_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2a6_file1_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2a6_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2a6_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2a6_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2a6_file2)"
                                v-if="pm.p2a6_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2a6_file2_status == 'Sesuai'"
                                v-model="pm.p2a6_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2a6_file2_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2a6_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2a6_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2a6_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2a6_file3)"
                                v-if="pm.p2a6_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2a6_file3_status == 'Sesuai'"
                                v-model="pm.p2a6_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2a6_file3_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2a6_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2a6_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2a6_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat notula sosialisasi
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2a6_file4)"
                                v-if="pm.p2a6_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2a6_file4_status == 'Sesuai'"
                                v-model="pm.p2a6_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2a6_file4_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2a6_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2a6_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2a6_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                  digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2a6_file5)"
                                v-if="pm.p2a6_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2a6_file5_status == 'Sesuai'"
                                v-model="pm.p2a6_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2a6_file5_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2a6_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2a6_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2a6_file6 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Kegiatan sosialisasi dipublikasikan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2a6_file6)"
                                v-if="pm.p2a6_file6 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2a6_file6_status == 'Sesuai'"
                                v-model="pm.p2a6_file6_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2a6_file6_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2a6_file6_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2a6_file6_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div
                                  class="card-body text-green"
                                  style="color: white"
                                >
                                  <p style="color: white">Informasi</p>
                                  <p style="text-align: justify">
                                    Penyusunan Perda dan Perkada berdasarkan
                                    Pasal 32 ayat (2), dan Pasal 39 ayat (1) PP
                                    No. 6 Tahun 2021 tentang Penyelenggaraan
                                    Perizinan Berusaha di Daerah, Pemda wajib
                                    menyesuaikan Perda dan Perkada paling lama 2
                                    (dua) bulan terhitung sejak PP No. 6 Tahun
                                    2021 diundangkan. Perda dan Perkada dimaksud
                                    adalah:
                                  </p>

                                  <ol style="margin-left: 11px">
                                    <li>
                                      Perda mengenai Rencana Tata Ruang Wilayah
                                      (RTRW)
                                    </li>
                                    <li>
                                      Perkada mengenai Rencana Detail Tata Ruang
                                      (RDTR)
                                    </li>
                                  </ol>

                                  <p>Untuk penetapan bobot penilaian:</p>

                                  <ol style="margin-left: 11px">
                                    <li>
                                      Nilai &ldquo;Ada, lebih dari 1 kali&rdquo;
                                      apabila kelima ketentuan di atas
                                      terpenuhi.
                                    </li>
                                    <li>
                                      Nilai &ldquo;Ada 1 kali&rdquo; apabila
                                      salah satu dari ketentuan di atas tidak
                                      terpenuhi.
                                    </li>
                                    <li>
                                      Nilai &ldquo;Tidak ada&rdquo; apabila
                                      seluruh ketentuan di atas tidak terpenuhi.
                                    </li>
                                  </ol>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5" style="background-color: #ebedf3">
                              <b
                                >Peraturan daerah atau Peraturan kepala Daerah
                                terkait persetujuan lingkungan dan
                                pengawasannya</b
                              >
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="2">1</td>
                            <td rowspan="2">
                              Apakah ada Perda dan atau Perkada di dalam dokumen
                              Prolegda?
                            </td>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2b1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Penilaian keberadaan Perkada dalam program
                                  pembentukan peraturan kepala daerah
                                  (Propemperkada) dilakukan dengan ketentuan
                                  <div class="row">
                                    <div class="col-1">a.</div>
                                    <div class="col-11">
                                      Kepala daerah menerbitkan peraturan kepala
                                      daerah mengenai pendelegasian kewenangan
                                      penyelenggaraan persetujuan lingkungan
                                      kepada Kepala DPMPTSP
                                    </div>
                                    <div class="col-1">b.</div>
                                    <div class="col-11">
                                      Kepala daerah menerbitkan peraturan kepala
                                      daerah mengenai pendelegasian kewenangan
                                      penyelenggaraan persetujuan lingkungan
                                      kepada Dinas Lingkungan Hidup
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2b1_file1)"
                                v-if="pm.p2b1_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2b1_file1_status == 'Sesuai'"
                                v-model="pm.p2b1_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2b1_file1_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2b1_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2b1_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Keberadaan Perkada berdasarkan Pasal 1 angka
                                    4 PP No. 22 Tahun 2021 tentang
                                    Penyelenggaraan Perlindungan dan Pengelolaan
                                    Lingkungan Hidup, Persetujuan Lingkungan
                                    adalah Keputusan Kelayakan Lingkungan Hidup
                                    atau Pernyataan Kesanggupan Pengelolaan
                                    Lingkungan Hidup yang telah mendapatkan
                                    persetujuan dari pemerintah pusat atau
                                    pemerintah daerah.
                                  </p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada” apabila salah satu ketentuan
                                    di atas terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila ketentuan di
                                    atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">2</td>
                            <td rowspan="7">
                              Apakah ada Konsultasi pengusulan Perda dan Perkada
                              dengan OPD daerah?
                            </td>
                            <td colspan="2" style="text-align: justify">
                              Keberadaan Perkada berdasarkan Pasal 1 angka 4 PP
                              No. 22 Tahun 2021 tentang Penyelenggaraan
                              Perlindungan dan Pengelolaan Lingkungan Hidup,
                              Persetujuan Lingkungan adalah Keputusan Kelayakan
                              Lingkungan Hidup atau Pernyataan Kesanggupan
                              Pengelolaan Lingkungan Hidup yang telah
                              mendapatkan persetujuan dari pemerintah pusat atau
                              pemerintah daerah. Penilaian konsultasi pengusulan
                              Perkada dengan OPD teknis dilakukan dengan
                              ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2b2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Konsultasi dilakukan sekurang-kurangnya 1 kali
                                  dalam 3 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2b2_file1)"
                                v-if="pm.p2b2_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2b2_file1_status == 'Sesuai'"
                                v-model="pm.p2b2_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2b2_file1_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2b2_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2b2_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2b2_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat surat undangan resmi (melibatkan
                                  pihak eksternal) dalam bentuk fisik atau
                                  digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2b2_file2)"
                                v-if="pm.p2b2_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2b2_file2_status == 'Sesuai'"
                                v-model="pm.p2b2_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2b2_file2_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2b2_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2b2_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2b2_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2b2_file3)"
                                v-if="pm.p2b2_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2b2_file3_status == 'Sesuai'"
                                v-model="pm.p2b2_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2b2_file3_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2b2_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2b2_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2b2_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2b2_file4)"
                                v-if="pm.p2b2_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2b2_file4_status == 'Sesuai'"
                                v-model="pm.p2b2_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2b2_file4_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2b2_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2b2_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2b2_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2b2_file5)"
                                v-if="pm.p2b2_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2b2_file5_status == 'Sesuai'"
                                v-model="pm.p2b2_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2b2_file5_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2b2_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2b2_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada 1 kali” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">3</td>
                            <td rowspan="7">
                              Apakah ada Konsultasi pengusulan Perda dan Perkada
                              dengan Kementerian Dalam Negeri, Kementerian
                              Investasi, dan Kementerian Teknis lainnya?
                            </td>
                            <td colspan="3" style="text-align: justify">
                              Keberadaan Perkada berdasarkan Pasal 1 angka 4 PP
                              No. 22 Tahun 2021 tentang Penyelenggaraan
                              Perlindungan dan Pengelolaan Lingkungan Hidup,
                              Persetujuan Lingkungan adalah Keputusan Kelayakan
                              Lingkungan Hidup atau Pernyataan Kesanggupan
                              Pengelolaan Lingkungan Hidup yang telah
                              mendapatkan persetujuan dari pemerintah pusat atau
                              pemerintah daerah. Penilaian konsultasi pengusulan
                              Perkada dengan Kementerian Dalam Negeri,
                              Kementerian Investasi/BKPM dan Kementerian Teknis
                              lainnya dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2b3_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Konsultasi dilakukan sekurang-kurangnya 1 kali
                                  dalam 3 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2b3_file1)"
                                v-if="pm.p2b3_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2b3_file1_status == 'Sesuai'"
                                v-model="pm.p2b3_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2b3_file1_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2b3_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2b3_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2b3_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat surat undangan resmi (melibatkan
                                  pihak eksternal) dalam bentuk fisik atau
                                  digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2b3_file2)"
                                v-if="pm.p2b3_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2b3_file2_status == 'Sesuai'"
                                v-model="pm.p2b3_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2b3_file2_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2b3_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2b3_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2b3_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2b3_file3)"
                                v-if="pm.p2b3_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2b3_file3_status == 'Sesuai'"
                                v-model="pm.p2b3_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2b3_file3_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2b3_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2b3_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2b3_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2b3_file4)"
                                v-if="pm.p2b3_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2b3_file4_status == 'Sesuai'"
                                v-model="pm.p2b3_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2b3_file4_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2b3_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2b3_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2b3_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                  digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2b3_file5)"
                                v-if="pm.p2b3_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2b3_file5_status == 'Sesuai'"
                                v-model="pm.p2b3_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2b3_file5_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2b3_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2b3_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada 1 kali” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">4</td>
                            <td rowspan="7">
                              Apakah ada koordinasi pengusulan Perda dan Perkada
                              dengan Kementrian Dalam Negeri, Kementrian
                              Investasi, dan Kementrian Teknis lainnya?
                            </td>
                            <td colspan="3" style="text-align: justify">
                              Keberadaan Perkada berdasarkan Pasal 1 angka 4 PP
                              No. 22 Tahun 2021 tentang Penyelenggaraan
                              Perlindungan dan Pengelolaan Lingkungan Hidup,
                              Persetujuan Lingkungan adalah Keputusan Kelayakan
                              Lingkungan Hidup atau Pernyataan Kesanggupan
                              Pengelolaan Lingkungan Hidup yang telah
                              mendapatkan persetujuan dari pemerintah pusat atau
                              pemerintah daerah. Penilaian koordinasi pengusulan
                              Perkada dengan Kementerian Dalam Negeri,
                              Kementerian Investasi/BKPM dan Kementerian Teknis
                              lainnya dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2b4_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Konsultasi dilakukan sekurang-kurangnya 1 kali
                                  dalam 3 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2b4_file1)"
                                v-if="pm.p2b4_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2b4_file1_status == 'Sesuai'"
                                v-model="pm.p2b4_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2b4_file1_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2b4_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2b4_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2b4_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat surat undangan resmi (melibatkan
                                  pihak eksternal) dalam bentuk fisik atau
                                  digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2b4_file2)"
                                v-if="pm.p2b4_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2b4_file2_status == 'Sesuai'"
                                v-model="pm.p2b4_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2b4_file2_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2b4_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2b4_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2b4_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2b4_file3)"
                                v-if="pm.p2b4_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2b4_file3_status == 'Sesuai'"
                                v-model="pm.p2b4_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2b4_file3_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2b4_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2b4_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2b4_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2b4_file4)"
                                v-if="pm.p2b4_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2b4_file4_status == 'Sesuai'"
                                v-model="pm.p2b4_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2b4_file4_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2b4_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2b4_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2b4_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                  digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2b4_file5)"
                                v-if="pm.p2b4_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2b4_file5_status == 'Sesuai'"
                                v-model="pm.p2b4_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2b4_file5_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2b4_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2b4_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada 1 kali” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="2">5</td>
                            <td rowspan="2">
                              Apakah ada dokumen Peraturan Daerah atau Peraturan
                              Kepala Daerah terkait pengawasan dan perizinan
                              lingkungan?
                            </td>
                            <td>
                              <b-form-group v-slot="{ ariaDescribedby }">
                                <b-form-radio
                                  disabled
                                  v-model="pm.p2b5_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p2b5_nilai"
                                  value="25"
                                  >Ada, sudah disahkan</b-form-radio
                                >
                                <b-form-radio
                                  disabled
                                  v-model="pm.p2b5_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p2b5_nilai"
                                  value="10"
                                  >Ada, belum disahkan</b-form-radio
                                >
                                <b-form-radio
                                  disabled
                                  v-model="pm.p2b5_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p2b5_nilai"
                                  value="0"
                                  >Belum ada</b-form-radio
                                >
                              </b-form-group>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2b5_file1)"
                                v-if="pm.p2b5_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2b5_nilai_status == 'Sesuai'"
                                v-model="pm.p2b5_nilai_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2b5_nilai_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2b5_nilai_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2b5_nilai_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div
                                  class="card-body text-green"
                                  style="color: white; text-align: justify"
                                >
                                  <p style="color: white">Informasi</p>
                                  <p>
                                    Keberadaan Perkada berdasarkan Pasal 1 angka
                                    4 PP No. 22 Tahun 2021 tentang
                                    Penyelenggaraan Perlindungan dan Pengelolaan
                                    Lingkungan Hidup, Persetujuan Lingkungan
                                    adalah Keputusan Kelayakan Lingkungan Hidup
                                    atau Pernyataan Kesanggupan Pengelolaan
                                    Lingkungan Hidup yang telah mendapatkan
                                    persetujuan dari pemerintah pusat atau
                                    pemerintah daerah. Penilaian ada tidaknya
                                    Peraturan Kepala Daerah terkait pengawasan
                                    dan perizinan lingkungan dilakukan dengan
                                    ketentuan:
                                  </p>

                                  <ol style="margin-left: 11px">
                                    <li>
                                      Kepala daerah menerbitkan peraturan kepala
                                      daerah mengenai pendelegasian kewenangan
                                      penyelenggaraan persetujuan lingkungan
                                      kepada Kepala DPMPTSP dalam lembaran
                                      daerah
                                    </li>
                                    <li>
                                      Kepala daerah menerbitkan peraturan kepala
                                      daerah mengenai pendelegasian kewenangan
                                      penyelenggaraan persetujuan lingkungan
                                      kepada Dinas Lingkungan Hidup dalam
                                      lembaran daerah
                                    </li>
                                  </ol>

                                  <p>Untuk penetapan bobot penilaian:</p>

                                  <ol style="margin-left: 11px">
                                    <li>
                                      Nilai &ldquo;Ada, sudah disahkan&rdquo;
                                      apabila salah satu ketentuan di atas
                                      terpenuhi.
                                    </li>
                                    <li>
                                      Nilai &ldquo;Ada, belum disahkan&rdquo;
                                      apabila seluruh ketentuan di atas tidak
                                      terpenuhi namun sudah tersedia dalam
                                      bentuk naskah/dokumen usulan.
                                    </li>
                                    <li>
                                      Nilai &ldquo;Belum ada&rdquo; apabila
                                      seluruh ketentuan di atas tidak terpenuhi.
                                    </li>
                                  </ol>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">6</td>
                            <td rowspan="7">
                              Apakah ada Sosialisasi Perda atau Perkada terkait
                              pengawasan dan perizinan lingkungan?
                            </td>
                            <td colspan="3" style="text-align: justify">
                              Keberadaan Perkada berdasarkan Pasal 1 angka 4 PP
                              No. 22 Tahun 2021 tentang Penyelenggaraan
                              Perlindungan dan Pengelolaan Lingkungan Hidup,
                              Persetujuan Lingkungan adalah Keputusan Kelayakan
                              Lingkungan Hidup atau pernyataan Kesanggupan
                              Pengelolaan Lingkungan Hidup yang telah
                              mendapatkan persetujuan dari pemerintah pusat atau
                              pemerintah daerah. Penilaian ada tidaknya
                              sosialisasi Perkada terkait pengawasan dan
                              perizinan lingkungan dilakukan dengan ketentuan
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2b6_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Sosialisasi dilakukan sekurang-kurangnya 1
                                  kali dalam 3 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2b6_file1)"
                                v-if="pm.p2b6_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2b6_file1_status == 'Sesuai'"
                                v-model="pm.p2b6_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2b6_file1_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2b6_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2b6_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2b6_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2b6_file2)"
                                v-if="pm.p2b6_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2b6_file2_status == 'Sesuai'"
                                v-model="pm.p2b6_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2b6_file2_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2b6_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2b6_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2b6_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2b6_file3)"
                                v-if="pm.p2b6_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2b6_file3_status == 'Sesuai'"
                                v-model="pm.p2b6_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2b6_file3_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2b6_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2b6_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2b6_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat notula sosialisasi
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2b6_file4)"
                                v-if="pm.p2b6_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2b6_file4_status == 'Sesuai'"
                                v-model="pm.p2b6_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2b6_file4_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2b6_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2b6_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2b6_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2b6_file5)"
                                v-if="pm.p2b6_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2b6_file5_status == 'Sesuai'"
                                v-model="pm.p2b6_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2b6_file5_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2b6_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2b6_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada 1 kali” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5" style="background-color: #ebedf3">
                              <b
                                >Peraturan daerah atau Peraturan kepala daerah
                                terkait persetujuan bangunan gedung melalui
                                sistem elektronik, pengawasan pelaksanaan
                                konstruksi bangunan Gedung, dan pembinaan
                                bangunan Gedung</b
                              >
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="2">1</td>
                            <td rowspan="2">
                              Apakah ada Perda atau Perkada dalam dokumen
                              Prolegda?
                            </td>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2c1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11" style="text-align: justify">
                                  Penilaian keberadaan Perda dalam program
                                  pembentukan peraturan daerah (Propemperda)
                                  dan/atau Perkada dalam program pembentukan
                                  peraturan kepala daerah (Propemperkada)
                                  dilakukan dengan ketentuan
                                  <div class="row">
                                    <div class="col-1">a.</div>
                                    <div class="col-11">
                                      Dokumen Perda mengenai Retribusi
                                      Persetujuan Bangunan Gedung dalam
                                      Propemperda
                                    </div>
                                    <div class="col-1">b.</div>
                                    <div class="col-11">
                                      Dokumen Perkada mengenai tata cara
                                      penyelenggaraan PBG dan Sertifikat Laik
                                      Fungsi (SLF) melalui Sistem Informasi
                                      Manajemen Bangunan Gedung (SIMBG) dalam
                                      Propemperkada
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2c1_file1)"
                                v-if="pm.p2c1_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2c1_file1_status == 'Sesuai'"
                                v-model="pm.p2c1_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2c1_file1_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2c1_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2c1_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div
                                  class="card-body text-green"
                                  style="color: white; text-align: justify"
                                >
                                  <p>Informasi</p>
                                  <p>
                                    Penyusunan Perda dan Perkada berdasarkan
                                    Pasal 347 ayat (2) PP No. 16 Tahun 2021
                                    tentang Peraturan Pelaksanaan Undang-Undang
                                    Nomor 28 Tahun 2002 Tentang Bangunan Gedung,
                                    Pemerintah kabupaten/kota wajib menyediakan
                                    Persetujuan Bangunan Gedung (PBG) paling
                                    lama 6 (enam) bulan terhitung sejak PP No.
                                    16 Tahun 2021 diundangkan melalui
                                    penetapan::
                                  </p>

                                  <ol style="margin-left: 11px">
                                    <li>
                                      Peraturan daerah mengenai Retribusi
                                      Persetujuan Bangunan Gedung; dan/atau
                                    </li>
                                    <li>
                                      Peraturan kepala daerah mengenai tata cara
                                      penyelenggaraan PBG dan Sertifikat Laik
                                      Fungsi (SLF) melalui Sistem Informasi
                                      Manajemen Bangunan Gedung (SIMBG)
                                    </li>
                                  </ol>

                                  <p>Untuk penetapan bobot penilaian:</p>

                                  <ol style="margin-left: 11px">
                                    <li>
                                      Nilai &ldquo;Ada&rdquo; apabila dapat
                                      menunjukkan naskah/dokumen usulan perda
                                      mengenai Retribusi PBG dan Perkada
                                      mengenai tata cara penyelenggaraan PBG dan
                                      SLF melalui Sistem Informasi Manajemen
                                      Bangunan Gedung (SIMBG).
                                    </li>
                                    <li>
                                      Nilai &ldquo;Tidak ada&rdquo; apabila
                                      ketentuan di atas tidak terpenuhi.
                                    </li>
                                  </ol>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">2</td>
                            <td rowspan="7">
                              Apakah ada Konsultasi pengusulan Perda dan Perkada
                              dengan OPD daerah?
                            </td>
                            <td colspan="3" style="text-align: justify">
                              Penyusunan Perda dan Perkada berdasarkan Pasal 347
                              ayat (2) PP No. 16 Tahun 2021 tentang Peraturan
                              Penilaian konsultasi pengusulan Perda dan Perkada
                              dengan OPD dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2c2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Konsultasi dilakukan sekurang-kurangnya 1 kali
                                  dalam 3 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2c2_file1)"
                                v-if="pm.p2c2_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2c2_file1_status == 'Sesuai'"
                                v-model="pm.p2c2_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2c2_file1_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2c2_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2c2_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2c2_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat surat undangan resmi (melibatkan
                                  pihak eksternal) dalam bentuk fisik atau
                                  digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2c2_file2)"
                                v-if="pm.p2c2_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2c2_file2_status == 'Sesuai'"
                                v-model="pm.p2c2_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2c2_file2_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2c2_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2c2_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2c2_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2c2_file3)"
                                v-if="pm.p2c2_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2c2_file3_status == 'Sesuai'"
                                v-model="pm.p2c2_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2c2_file3_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2c2_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2c2_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2c2_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat notula sosialisasi
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2c2_file4)"
                                v-if="pm.p2c2_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2c2_file4_status == 'Sesuai'"
                                v-model="pm.p2c2_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2c2_file4_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2c2_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2c2_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2c2_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2c2_file5)"
                                v-if="pm.p2c2_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2c2_file5_status == 'Sesuai'"
                                v-model="pm.p2c2_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2c2_file5_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2c2_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2c2_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div
                                  class="card-body text-green"
                                  style="color: white; text-align: justify"
                                >
                                  <p>Informasi</p>
                                  <p>
                                    Pelaksanaan Undang-Undang Nomor 28 Tahun
                                    2002 Tentang Bangunan Gedung, Pemerintah
                                    kabupaten/kota wajib menyediakan Persetujuan
                                    Bangunan Gedung (PBG) paling lama 6 (enam)
                                    bulan terhitung sejak PP No. 16 Tahun 2021
                                    diundangkan melalui penetapan:
                                  </p>

                                  <ol style="margin-left: 11px">
                                    <li>
                                      Peraturan daerah mengenai Retribusi
                                      Persetujuan Bangunan Gedung; dan/atau
                                    </li>
                                    <li>
                                      Peraturan kepala daerah mengenai tata cara
                                      penyelenggaraan PBG dan Sertifikat Laik
                                      Fungsi (SLF) melalui Sistem Informasi
                                      Manajemen Bangunan Gedung (SIMBG)
                                    </li>
                                  </ol>

                                  <p>Untuk penetapan bobot penilaian:</p>

                                  <ol style="margin-left: 11px">
                                    <li>
                                      Nilai &ldquo;Ada, lebih dari 1 kali&rdquo;
                                      apabila kelima ketentuan di atas
                                      terpenuhi.
                                    </li>
                                    <li>
                                      Nilai &ldquo;Ada 1 kali&rdquo; apabila
                                      salah satu dari ketentuan di atas tidak
                                      terpenuhi.
                                    </li>
                                    <li>
                                      Nilai &ldquo;Tidak ada&rdquo; apabila
                                      seluruh ketentuan di atas tidak terpenuhi.
                                    </li>
                                  </ol>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">3</td>
                            <td rowspan="7">
                              Apakah ada pengusulan Perda dan Perkada dengan
                              Kementerian Dalam Negeri, Kementerian Investasi,
                              dan Kementerian Teknis lainnya?
                            </td>
                            <td colspan="3" style="text-align: justify">
                              Penilaian konsultasi pengusulan Perda dan Perkada
                              dengan Kementerian Dalam Negeri, Kementerian
                              Investasi/BKPM dan Kementerian Teknis lainnya
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2c3_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  konsultasi dilakukan sekurang-kurangnya 1 kali
                                  dalam 3 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2c3_file1)"
                                v-if="pm.p2c3_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2c3_file1_status == 'Sesuai'"
                                v-model="pm.p2c3_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2c3_file1_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2c3_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2c3_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2c3_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat surat undangan resmi (melibatkan
                                  pihak eksternal) dalam bentuk fisik atau
                                  digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2c3_file2)"
                                v-if="pm.p2c3_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2c3_file2_status == 'Sesuai'"
                                v-model="pm.p2c3_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2c3_file2_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2c3_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2c3_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2c3_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2c3_file3)"
                                v-if="pm.p2c3_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2c3_file3_status == 'Sesuai'"
                                v-model="pm.p2c3_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2c3_file3_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2c3_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2c3_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2c3_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2c3_file4)"
                                v-if="pm.p2c3_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2c3_file4_status == 'Sesuai'"
                                v-model="pm.p2c3_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2c3_file4_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2c3_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2c3_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2c3_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                  digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2c3_file5)"
                                v-if="pm.p2c3_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2c3_file5_status == 'Sesuai'"
                                v-model="pm.p2c3_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2c3_file5_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2c3_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2c3_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div
                                  class="card-body text-green"
                                  style="color: white; text-align: justify"
                                >
                                  <p>Informasi</p>
                                  <p>
                                    Penyusunan Perda dan Perkada berdasarkan
                                    Pasal 347 ayat (2) PP No. 16 Tahun 2021
                                    tentang Peraturan Pelaksanaan Undang-Undang
                                    Nomor 28 Tahun 2002 Tentang Bangunan Gedung,
                                    Pemerintah kabupaten/kota wajib menyediakan
                                    Persetujuan Bangunan Gedung (PBG) paling
                                    lama 6 (enam) bulan terhitung sejak PP No.
                                    16 Tahun 2021 diundangkan melalui penetapan:
                                  </p>

                                  <ol style="margin-left: 11px">
                                    <li>
                                      Peraturan daerah mengenai Retribusi
                                      Persetujuan Bangunan Gedung atau
                                    </li>
                                    <li>
                                      Peraturan kepala daerah mengenai tata cara
                                      penyelenggaraan PBG dan Sertifikat Laik
                                      Fungsi (SLF) melalui Sistem Informasi
                                      Manajemen Bangunan Gedung (SIMBG)
                                    </li>
                                  </ol>

                                  <p>Untuk penetapan bobot penilaian:</p>

                                  <ol style="margin-left: 11px">
                                    <li>
                                      Nilai &ldquo;Ada, lebih dari 1 kali&rdquo;
                                      apabila kelima ketentuan di atas
                                      terpenuhi.
                                    </li>
                                    <li>
                                      Nilai &ldquo;Ada 1 kali&rdquo; apabila
                                      salah satu dari ketentuan di atas tidak
                                      terpenuhi.
                                    </li>
                                    <li>
                                      Nilai &ldquo;Tidak ada&rdquo; apabila
                                      seluruh ketentuan di atas tidak terpenuhi.
                                    </li>
                                  </ol>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">4</td>
                            <td rowspan="7">
                              Apakah ada koordinasi pengusulan Perda dan Perkada
                              dengan Kementerian Dalam Negeri, Kementerian
                              Investasi, dan Kementerian Teknis lainnya?
                            </td>
                            <td colspan="3" style="text-align: justify">
                              Penilaian koordinasi pengusulan Perda dan Perkada
                              dengan Kementerian Dalam Negeri, Kementerian
                              Investasi/BKPM dan Kementerian Teknis lainnya
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2c4_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Koordinasi dilakukan sekurang-kurangnya 1 kali
                                  dalam 3 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2c4_file1)"
                                v-if="pm.p2c4_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2c4_file1_status == 'Sesuai'"
                                v-model="pm.p2c4_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2c4_file1_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2c4_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2c4_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2c4_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat surat undangan resmi (melibatkan
                                  pihak eksternal) dalam bentuk fisik atau
                                  digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2c4_file2)"
                                v-if="pm.p2c4_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2c4_file2_status == 'Sesuai'"
                                v-model="pm.p2c4_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2c4_file2_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2c4_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2c4_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2c4_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2c4_file3)"
                                v-if="pm.p2c4_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2c4_file3_status == 'Sesuai'"
                                v-model="pm.p2c4_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2c4_file3_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2c4_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2c4_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2c4_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2c4_file4)"
                                v-if="pm.p2c4_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2c4_file4_status == 'Sesuai'"
                                v-model="pm.p2c4_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2c4_file4_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2c4_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2c4_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2c4_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                  digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2c4_file5)"
                                v-if="pm.p2c4_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2c4_file5_status == 'Sesuai'"
                                v-model="pm.p2c4_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2c4_file5_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2c4_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2c4_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div
                                  class="card-body text-green"
                                  style="color: white; text-align: justify"
                                >
                                  <p>Informasi</p>
                                  <p>
                                    Penyusunan Perda dan Perkada berdasarkan
                                    Pasal 347 ayat (2) PP No. 16 Tahun 2021
                                    tentang Peraturan Pelaksanaan Undang-Undang
                                    Nomor 28 Tahun 2002 Tentang Bangunan Gedung,
                                    Pemerintah kabupaten/kota wajib menyediakan
                                    Persetujuan Bangunan Gedung (PBG) paling
                                    lama 6 (enam) bulan terhitung sejak PP No.
                                    16 Tahun 2021 diundangkan melalui penetapan:
                                  </p>

                                  <ol style="margin-left: 11px">
                                    <li>
                                      Peraturan daerah mengenai Retribusi
                                      Persetujuan Bangunan Gedung atau
                                    </li>
                                    <li>
                                      Peraturan kepala daerah mengenai tata cara
                                      penyelenggaraan PBG dan Sertifikat Laik
                                      Fungsi (SLF) melalui Sistem Informasi
                                      Manajemen Bangunan Gedung (SIMBG)
                                    </li>
                                  </ol>

                                  <p>Untuk penetapan bobot penilaian:</p>

                                  <ol style="margin-left: 11px">
                                    <li>
                                      Nilai &ldquo;Ada, lebih dari 1 kali&rdquo;
                                      apabila kelima ketentuan di atas
                                      terpenuhi.
                                    </li>
                                    <li>
                                      Nilai &ldquo;Ada 1 kali&rdquo; apabila
                                      salah satu dari ketentuan di atas tidak
                                      terpenuhi.
                                    </li>
                                    <li>
                                      Nilai &ldquo;Tidak ada&rdquo; apabila
                                      seluruh ketentuan di atas tidak terpenuhi.
                                    </li>
                                  </ol>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="2">5</td>
                            <td rowspan="2">
                              Apakah ada dokumen Peraturan daerah atau Peraturan
                              Kepala Daerah terkait persetujuan bangunan Gedung
                              melalui sistem elektronik, pengawasan pelaksaan
                              konstruksi bangunan Gedung, dan pembinaan bangunan
                              Gedung?
                            </td>
                            <td>
                              <b-form-group v-slot="{ ariaDescribedby }">
                                <b-form-radio
                                  disabled
                                  v-model="pm.p2c5_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p2c5_nilai"
                                  value="25"
                                  >Ada, sudah disahkan</b-form-radio
                                >
                                <b-form-radio
                                  disabled
                                  v-model="pm.p2c5_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p2c5_nilai"
                                  value="10"
                                  >Ada, belum disahkan</b-form-radio
                                >
                                <b-form-radio
                                  disabled
                                  v-model="pm.p2c5_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p2c5_nilai"
                                  value="0"
                                  >Belum ada</b-form-radio
                                >
                              </b-form-group>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2c5_file1)"
                                v-if="pm.p2c5_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2c5_nilai_status == 'Sesuai'"
                                v-model="pm.p2c5_nilai_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2c5_nilai_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2c5_nilai_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2c5_nilai_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div
                                  class="card-body text-green"
                                  style="color: white; text-align: justify"
                                >
                                  <p>Informasi</p>
                                  <p>
                                    Penyusunan Perda dan Perkada berdasarkan
                                    Pasal 347 ayat (2) PP No. 16 Tahun 2021
                                    tentang Peraturan Pelaksanaan Undang-Undang
                                    Nomor 28 Tahun 2002 Tentang Bangunan Gedung,
                                    Pemerintah kabupaten/kota wajib menyediakan
                                    Persetujuan Bangunan Gedung (PBG) paling
                                    lama 6 (enam) bulan terhitung sejak PP No.
                                    16 Tahun 2021 diundangkan melalui
                                    penetapan::
                                  </p>

                                  <ol style="margin-left: 11px">
                                    <li>
                                      Peraturan daerah mengenai Retribusi
                                      Persetujuan Bangunan Gedung atau
                                    </li>
                                    <li>
                                      Peraturan kepala daerah mengenai tata cara
                                      penyelenggaraan PBG dan Sertifikat Laik
                                      Fungsi (SLF) melalui Sistem Informasi
                                      Manajemen Bangunan Gedung (SIMBG)
                                    </li>
                                  </ol>

                                  <p>Untuk penetapan bobot penilaian:</p>

                                  <ol style="margin-left: 11px">
                                    <li>
                                      Nilai &ldquo;Ada, sudah disahkan&rdquo;
                                      apabila salah satu ketentuan di atas
                                      terpenuhi.
                                    </li>
                                    <li>
                                      Nilai &ldquo;Ada, belum disahkan&rdquo;
                                      apabila menunjukkan naskah usulan Perda
                                      mengenai retribusi penyelenggaraan PBG
                                      dan/atau Perkada mengenai penyelenggaraan
                                      PBG.
                                    </li>
                                    <li>
                                      Nilai &ldquo;Belum ada&rdquo; apabila
                                      seluruh ketentuan di atas tidak terpenuhi.
                                    </li>
                                  </ol>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">6</td>
                            <td rowspan="7">
                              Apakah ada kegiatan Sosialisasi Perda atau Perkada
                              terkait persetujuan bangunan Gedung melalu system
                              elektronik, pengawasan pelaksaan konstruksi
                              bangunan Gedung, dan pembinaan bangunan Gedung?
                            </td>
                            <td colspan="3" style="text-align: justify">
                              Penilaian ada tidaknya sosialisasi Perda atau
                              Perkada terkait persetujuan bangunan gedung
                              melalui sistem elektronik, pengawasan pelaksanaan
                              konstruksi bangunan Gedung, dan pembinaan bangunan
                              Gedung dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2c6_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Sosialisasi dilakukan sekurang-kurangnya 1
                                  kali dalam 3 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2c6_file1)"
                                v-if="pm.p2c6_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2c6_file1_status == 'Sesuai'"
                                v-model="pm.p2c6_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2c6_file1_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2c6_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2c6_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2c6_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2c6_file2)"
                                v-if="pm.p2c6_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2c6_file2_status == 'Sesuai'"
                                v-model="pm.p2c6_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2c6_file2_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2c6_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2c6_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2c6_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2c6_file3)"
                                v-if="pm.p2c6_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2c6_file3_status == 'Sesuai'"
                                v-model="pm.p2c6_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2c6_file3_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2c6_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2c6_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2c6_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat notula sosialisasi
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2c6_file4)"
                                v-if="pm.p2c6_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2c6_file4_status == 'Sesuai'"
                                v-model="pm.p2c6_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2c6_file4_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2c6_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2c6_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2c6_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2c6_file5)"
                                v-if="pm.p2c6_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2c6_file5_status == 'Sesuai'"
                                v-model="pm.p2c6_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2c6_file5_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2c6_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2c6_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div
                                  class="card-body text-green"
                                  style="color: white; text-align: justify"
                                >
                                  <p>Informasi</p>
                                  <p>
                                    Penyusunan Perda dan Perkada berdasarkan
                                    Pasal 347 ayat (2) PP No. 16 Tahun 2021
                                    tentang Peraturan Pelaksanaan Undang-Undang
                                    Nomor 28 Tahun 2002 Tentang Bangunan Gedung,
                                    Pemerintah kabupaten/kota wajib menyediakan
                                    Persetujuan Bangunan Gedung (PBG) paling
                                    lama 6 (enam) bulan terhitung sejak PP No.
                                    16 Tahun 2021 diundangkan melalui penetapan:
                                  </p>

                                  <ol style="margin-left: 11px">
                                    <li>
                                      Peraturan daerah mengenai Retribusi
                                      Persetujuan Bangunan Gedung atau
                                    </li>
                                    <li>
                                      Peraturan kepala daerah mengenai tata cara
                                      penyelenggaraan PBG dan Sertifikat Laik
                                      Fungsi (SLF) melalui Sistem Informasi
                                      Manajemen Bangunan Gedung (SIMBG)
                                    </li>
                                  </ol>

                                  <p>Untuk penetapan bobot penilaian:</p>

                                  <ol style="margin-left: 11px">
                                    <li>
                                      Nilai &ldquo;Ada, lebih dari 1 kali&rdquo;
                                      apabila kelima ketentuan di atas
                                      terpenuhi.
                                    </li>
                                    <li>
                                      Nilai &ldquo;Ada 1 kali&rdquo; apabila
                                      salah satu dari ketentuan di atas tidak
                                      terpenuhi.
                                    </li>
                                    <li>
                                      Nilai &ldquo;Tidak ada&rdquo; apabila
                                      seluruh ketentuan di atas tidak terpenuhi.
                                    </li>
                                  </ol>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5" style="background-color: #ebedf3">
                              <b
                                >Peraturan daerah atau Peraturan kepala Daerah
                                terkait penerbitan Sertifikat Laik Fungsi
                                melalui sistem elektronik</b
                              >
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="4">1</td>
                            <td rowspan="4">
                              Apakah ada Perda dan/atau Perkada dalam dokumen
                              Prolegda?
                            </td>
                            <td colspan="3" style="text-align: justify">
                              Penilaian keberadaan Perda dan atau Perkada
                              dokumen program pembentukan peraturan daerah
                              (Propemperda) atau program pembentukan peraturan
                              kepala daerah (Propemperkada) dilakukan dengan
                              ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2d1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Dokumen Perda mengenai Retribusi Persetujuan
                                  Bangunan Gedung dalam Propemperda
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2d1_file1)"
                                v-if="pm.p2d1_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2d1_file1_status == 'Sesuai'"
                                v-model="pm.p2d1_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2d1_file1_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2d1_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2d1_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2d1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Dokumen Perkada mengenai tata cara
                                  penyelenggaraan PBG dan Sertifikat Laik Fungsi
                                  (SLF) melalui Sistem Informasi Manajemen
                                  Bangunan Gedung (SIMBG) dalam Propemperkada
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2d1_file2)"
                                v-if="pm.p2d1_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2d1_file2_status == 'Sesuai'"
                                v-model="pm.p2d1_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2d1_file2_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2d1_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2d1_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div
                                  class="card-body text-green"
                                  style="color: white; text-align: justify"
                                >
                                  <p>Informasi</p>
                                  <p>
                                    Penyusunan Perda dan Perkada berdasarkan
                                    Pasal 347 ayat (2) PP No. 16 Tahun 2021
                                    tentang Peraturan Pelaksanaan Undang-Undang
                                    Nomor 28 Tahun 2002 Tentang Bangunan Gedung,
                                    Pemerintah kabupaten/kota wajib menyediakan
                                    Persetujuan Bangunan Gedung (PBG) paling
                                    lama 6 (enam) bulan terhitung sejak PP No.
                                    16 Tahun 2021 diundangkan melalui penetapan:
                                  </p>

                                  <ol style="margin-left: 11px">
                                    <li>
                                      Peraturan daerah mengenai Retribusi
                                      Persetujuan Bangunan Gedung
                                    </li>
                                    <li>
                                      Peraturan kepala daerah mengenai tata cara
                                      penyelenggaraan PBG dan Sertifikat Laik
                                      Fungsi (SLF) melalui Sistem Informasi
                                      Manajemen Bangunan Gedung (SIMBG)
                                    </li>
                                  </ol>

                                  <p>Untuk penetapan bobot penilaian:</p>

                                  <ol style="margin-left: 11px">
                                    <li>
                                      Nilai &ldquo;Ada&rdquo; apabila seluruh
                                      ketentuan di atas terpenuhi.
                                    </li>
                                    <li>
                                      Nilai &ldquo;Tidak ada&rdquo; apabila
                                      ketentuan di atas tidak terpenuhi.
                                    </li>
                                  </ol>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">2</td>
                            <td rowspan="7">
                              Apakah ada konsultasi pengusulan Perda dan Perkada
                              dengan OPD Daerah?
                            </td>
                            <td colspan="3" style="text-align: justify">
                              Penilaian konsultasi pengusulan Perda dan Perkada
                              dengan OPD dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2d2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Konsultasi dilakukan sekurang-kurangnya 1 kali
                                  dalam 3 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2d2_file1)"
                                v-if="pm.p2d2_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2d2_file1_status == 'Sesuai'"
                                v-model="pm.p2d2_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2d2_file1_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2d2_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2d2_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2d2_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat surat undangan resmi (melibatkan
                                  pihak eksternal) dalam bentuk fisik atau
                                  digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2d2_file2)"
                                v-if="pm.p2d2_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2d2_file2_status == 'Sesuai'"
                                v-model="pm.p2d2_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2d2_file2_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2d2_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2d2_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2d2_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2d2_file3)"
                                v-if="pm.p2d2_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2d2_file3_status == 'Sesuai'"
                                v-model="pm.p2d2_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2d2_file3_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2d2_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2d2_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2d2_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2d2_file4)"
                                v-if="pm.p2d2_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2d2_file4_status == 'Sesuai'"
                                v-model="pm.p2d2_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2d2_file4_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2d2_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2d2_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2d2_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2d2_file5)"
                                v-if="pm.p2d2_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2d2_file5_status == 'Sesuai'"
                                v-model="pm.p2d2_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2d2_file5_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2d2_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2d2_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div
                                  class="card-body text-green"
                                  style="color: white; text-align: justify"
                                >
                                  <p>Informasi</p>
                                  <p>
                                    Penyusunan Perda dan Perkada berdasarkan
                                    Pasal 347 ayat (2) PP No. 16 Tahun 2021
                                    tentang Peraturan Pelaksanaan Undang-Undang
                                    Nomor 28 Tahun 2002 Tentang Bangunan Gedung,
                                    Pemerintah kabupaten/kota wajib menyediakan
                                    Persetujuan Bangunan Gedung (PBG) paling
                                    lama 6 (enam) bulan terhitung sejak PP No.
                                    16 Tahun 2021 diundangkan melalui penetapan:
                                  </p>

                                  <ol style="margin-left: 11px">
                                    <li>
                                      Peraturan daerah mengenai Retribusi
                                      Persetujuan Bangunan Gedung atau
                                    </li>
                                    <li>
                                      Peraturan kepala daerah mengenai tata cara
                                      penyelenggaraan PBG dan Sertifikat Laik
                                      Fungsi (SLF) melalui Sistem Informasi
                                      Manajemen Bangunan Gedung (SIMBG)
                                    </li>
                                  </ol>

                                  <p>Untuk penetapan bobot penilaian:</p>

                                  <ol style="margin-left: 11px">
                                    <li>
                                      Nilai &ldquo;Ada, lebih dari 1 kali&rdquo;
                                      apabila kelima ketentuan di atas
                                      terpenuhi.
                                    </li>
                                    <li>
                                      Nilai &ldquo;Ada 1 kali&rdquo; apabila
                                      salah satu dari ketentuan di atas tidak
                                      terpenuhi.
                                    </li>
                                    <li>
                                      Nilai &ldquo;Tidak ada&rdquo; apabila
                                      seluruh ketentuan di atas tidak terpenuhi.
                                    </li>
                                  </ol>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">3</td>
                            <td rowspan="7">
                              Apakah ada konsultasi pengusulan Perda dan Perkada
                              dengan Kementerian Dalam Negeri, Kementerian
                              Investasi dan kementerian teknis lainnya ?
                            </td>
                            <td colspan="3" style="text-align: justify">
                              Penilaian konsultasi pengusulan Perda dan Perkada
                              dengan Kementerian Dalam Negeri, Kementerian
                              Investasi/BKPM dan Kementerian Teknis lainnya
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2d3_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Konsultasi dilakukan sekurang-kurangnya 1 kali
                                  dalam 3 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2d3_file1)"
                                v-if="pm.p2d3_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2d3_file1_status == 'Sesuai'"
                                v-model="pm.p2d3_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2d3_file1_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2d3_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2d3_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2d3_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat surat undangan resmi (melibatkan
                                  pihak eksternal) dalam bentuk fisik atau
                                  digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2d3_file2)"
                                v-if="pm.p2d3_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2d3_file2_status == 'Sesuai'"
                                v-model="pm.p2d3_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2d3_file2_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2d3_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2d3_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2d3_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2d3_file3)"
                                v-if="pm.p2d3_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2d3_file3_status == 'Sesuai'"
                                v-model="pm.p2d3_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2d3_file3_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2d3_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2d3_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2d3_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2d3_file4)"
                                v-if="pm.p2d3_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2d3_file4_status == 'Sesuai'"
                                v-model="pm.p2d3_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2d3_file4_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2d3_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2d3_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2d3_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                  digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2d3_file5)"
                                v-if="pm.p2d3_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2d3_file5_status == 'Sesuai'"
                                v-model="pm.p2d3_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2d3_file5_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2d3_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2d3_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div
                                  class="card-body text-green"
                                  style="color: white; text-align: justify"
                                >
                                  <p>Informasi</p>
                                  <p>
                                    Penyusunan Perda dan Perkada berdasarkan
                                    Pasal 347 ayat (2) PP No. 16 Tahun 2021
                                    tentang Peraturan Pelaksanaan Undang-Undang
                                    Nomor 28 Tahun 2002 Tentang Bangunan Gedung,
                                    Pemerintah kabupaten/kota wajib menyediakan
                                    Persetujuan Bangunan Gedung (PBG) paling
                                    lama 6 (enam) bulan terhitung sejak PP No.
                                    16 Tahun 2021 diundangkan melalui penetapan:
                                  </p>

                                  <ol style="margin-left: 11px">
                                    <li>
                                      Peraturan daerah mengenai Retribusi
                                      Persetujuan Bangunan Gedung atau
                                    </li>
                                    <li>
                                      Peraturan kepala daerah mengenai tata cara
                                      penyelenggaraan PBG dan Sertifikat Laik
                                      Fungsi (SLF) melalui Sistem Informasi
                                      Manajemen Bangunan Gedung (SIMBG)
                                    </li>
                                  </ol>

                                  <p>Untuk penetapan bobot penilaian:</p>

                                  <ol style="margin-left: 11px">
                                    <li>
                                      Nilai &ldquo;Ada, lebih dari 1 kali&rdquo;
                                      apabila kelima ketentuan di atas
                                      terpenuhi.
                                    </li>
                                    <li>
                                      Nilai &ldquo;Ada 1 kali&rdquo; apabila
                                      salah satu dari ketentuan di atas tidak
                                      terpenuhi.
                                    </li>
                                    <li>
                                      Nilai &ldquo;Tidak ada&rdquo; apabila
                                      seluruh ketentuan di atas tidak terpenuhi.
                                    </li>
                                  </ol>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">4</td>
                            <td rowspan="7">
                              Apakah ada koordinasi pengusulan Perda dan Perkada
                              dengan Kementerian Dalam Negeri, Kementerian
                              Investasi dan kementerian teknis lainnya?
                            </td>
                            <td colspan="2" style="text-align: justify">
                              Penilaian koordinasi pengusulan Perda dan Perkada
                              dengan Kementerian Dalam Negeri, Kementerian
                              Investasi/BKPM dan Kementerian Teknis lainnya
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2d4_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Konsultasi dilakukan sekurang-kurangnya 1 kali
                                  dalam 3 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2d4_file1)"
                                v-if="pm.p2d4_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2d4_file1_status == 'Sesuai'"
                                v-model="pm.p2d4_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2d4_file1_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2d4_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2d4_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2d4_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat surat undangan resmi (melibatkan
                                  pihak eksternal) dalam bentuk fisik atau
                                  digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2d4_file2)"
                                v-if="pm.p2d4_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2d4_file2_status == 'Sesuai'"
                                v-model="pm.p2d4_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2d4_file2_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2d4_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2d4_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2d4_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2d4_file3)"
                                v-if="pm.p2d4_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2d4_file3_status == 'Sesuai'"
                                v-model="pm.p2d4_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2d4_file3_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2d4_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2d4_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2d4_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2d4_file4)"
                                v-if="pm.p2d4_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2d4_file4_status == 'Sesuai'"
                                v-model="pm.p2d4_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2d4_file4_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2d4_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2d4_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2d4_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                  digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2d4_file5)"
                                v-if="pm.p2d4_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2d4_file5_status == 'Sesuai'"
                                v-model="pm.p2d4_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2d4_file5_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2d4_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2d4_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div
                                  class="card-body text-green"
                                  style="color: white; text-align: justify"
                                >
                                  <p>Informasi</p>
                                  <p>
                                    Penyusunan Perda dan Perkada berdasarkan
                                    Pasal 347 ayat (2) PP No. 16 Tahun 2021
                                    tentang Peraturan Pelaksanaan Undang-Undang
                                    Nomor 28 Tahun 2002 Tentang Bangunan Gedung,
                                    Pemerintah kabupaten/kota wajib menyediakan
                                    Persetujuan Bangunan Gedung (PBG) paling
                                    lama 6 (enam) bulan terhitung sejak PP No.
                                    16 Tahun 2021 diundangkan melalui penetapan:
                                  </p>

                                  <ol style="margin-left: 11px">
                                    <li>
                                      Peraturan daerah mengenai Retribusi
                                      Persetujuan Bangunan Gedung atau
                                    </li>
                                    <li>
                                      Peraturan kepala daerah mengenai tata cara
                                      penyelenggaraan PBG dan Sertifikat Laik
                                      Fungsi (SLF) melalui Sistem Informasi
                                      Manajemen Bangunan Gedung (SIMBG)
                                    </li>
                                  </ol>

                                  <p>Untuk penetapan bobot penilaian:</p>

                                  <ol style="margin-left: 11px">
                                    <li>
                                      Nilai &ldquo;Ada, lebih dari 1 kali&rdquo;
                                      apabila kelima ketentuan di atas
                                      terpenuhi.
                                    </li>
                                    <li>
                                      Nilai &ldquo;Ada 1 kali&rdquo; apabila
                                      salah satu dari ketentuan di atas tidak
                                      terpenuhi.
                                    </li>
                                    <li>
                                      Nilai &ldquo;Tidak ada&rdquo; apabila
                                      seluruh ketentuan di atas tidak terpenuhi.
                                    </li>
                                  </ol>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="3">5</td>
                            <td rowspan="3">
                              Apakah ada dokumen Peraturan Daerah atau Peraturan
                              Kepala Daerah terkait penerbitan Sertifikat Laik
                              Fungsi melalui sistem elektronik?
                            </td>
                            <td rowspan="2">
                              <b-form-group v-slot="{ ariaDescribedby }">
                                <b-form-radio
                                  disabled
                                  v-model="pm.p2d5_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p2d5_nilai"
                                  value="25"
                                  >Ada, sudah disahkan</b-form-radio
                                >
                                <b-form-radio
                                  disabled
                                  v-model="pm.p2d5_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p2d5_nilai"
                                  value="10"
                                  >Ada, belum disahkan</b-form-radio
                                >
                                <b-form-radio
                                  disabled
                                  v-model="pm.p2d5_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p2d5_nilai"
                                  value="0"
                                  >Belum ada</b-form-radio
                                >
                              </b-form-group>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2d5_file1)"
                                v-if="pm.p2d5_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2d5_nilai_status == 'Sesuai'"
                                v-model="pm.p2d5_nilai_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2d5_nilai_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2d5_nilai_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2d5_nilai_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr colspan="3">
                            <td>
                              <button
                                @click="unduhFile(pm.p2d5_file2)"
                                v-if="pm.p2d5_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div
                                  class="card-body text-green"
                                  style="color: white; text-align: justify"
                                >
                                  <p>Informasi</p>
                                  <p>
                                    Penyusunan Perda dan Perkada berdasarkan
                                    Pasal 347 ayat (2) PP No. 16 Tahun 2021
                                    tentang Peraturan Pelaksanaan Undang-Undang
                                    Nomor 28 Tahun 2002 Tentang Bangunan Gedung,
                                    Pemerintah kabupaten/kota wajib menyediakan
                                    Persetujuan Bangunan Gedung (PBG) paling
                                    lama 6 (enam) bulan terhitung sejak PP No.
                                    16 Tahun 2021 diundangkan melalui penetapan:
                                  </p>
                                  <ol style="margin-left: 11px">
                                    <li>
                                      Peraturan daerah mengenai Retribusi
                                      Persetujuan Bangunan Gedung dan
                                    </li>
                                    <li>
                                      Peraturan kepala daerah mengenai tata cara
                                      penyelenggaraan PBG dan Sertifikat Laik
                                      Fungsi (SLF) melalui Sistem Informasi
                                      Manajemen Bangunan Gedung (SIMBG)
                                    </li>
                                  </ol>

                                  <p>Untuk penetapan bobot penilaian:</p>

                                  <ol style="margin-left: 11px">
                                    <li>
                                      Nilai &ldquo;Ada, sudah disahkan&rdquo;
                                      apabila seluruh ketentuan di atas
                                      terpenuhi.
                                    </li>
                                    <li>
                                      Nilai &ldquo;Ada, belum disahkan&rdquo;
                                      apabila seluruh ketentuan di atas tidak
                                      terpenuhi namun sudah tersedia dalam
                                      bentuk naskah/dokumen usulan.
                                    </li>
                                    <li>
                                      Nilai &ldquo;Belum ada&rdquo; apabila
                                      seluruh ketentuan di atas tidak terpenuhi.
                                    </li>
                                  </ol>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="7">6</td>
                            <td rowspan="7" style="text-align: justify">
                              Penilaian ada tidaknya sosialisasi Perda atau
                              Perkada terkait penerbitan Sertifikat Laik Fungsi
                              melalui sistem elektronik dilakukan dengan
                              ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2d6_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Sosialisasi dilakukan sekurang-kurangnya 1
                                  kali dalam 3 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2d6_file1)"
                                v-if="pm.p2d6_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2d6_file1_status == 'Sesuai'"
                                v-model="pm.p2d6_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2d6_file1_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2d6_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2d6_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2d6_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2d6_file2)"
                                v-if="pm.p2d6_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2d6_file2_status == 'Sesuai'"
                                v-model="pm.p2d6_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2d6_file2_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2d6_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2d6_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2d6_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2d6_file3)"
                                v-if="pm.p2d6_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2d6_file3_status == 'Sesuai'"
                                v-model="pm.p2d6_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2d6_file3_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2d6_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2d6_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2d6_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat notula sosialisasi
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2d6_file4)"
                                v-if="pm.p2d6_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2d6_file4_status == 'Sesuai'"
                                v-model="pm.p2d6_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2d6_file4_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2d6_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2d6_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2d6_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2d6_file5)"
                                v-if="pm.p2d6_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2d6_file5_status == 'Sesuai'"
                                v-model="pm.p2d6_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2d6_file5_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2d6_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2d6_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                class="card-body text-green"
                                style="color: white; text-align: justify"
                              >
                                <p>Informasi</p>
                                <p>
                                  Penyusunan Perda dan Perkada berdasarkan Pasal
                                  347 ayat (2) PP No. 16 Tahun 2021 tentang
                                  Peraturan Pelaksanaan Undang-Undang Nomor 28
                                  Tahun 2002 Tentang Bangunan Gedung, Pemerintah
                                  kabupaten/kota wajib menyediakan Persetujuan
                                  Bangunan Gedung (PBG) paling lama 6 (enam)
                                  bulan terhitung sejak PP No. 16 Tahun 2021
                                  diundangkan melalui penetapan:
                                </p>

                                <ol style="margin-left: 11px">
                                  <li>
                                    Peraturan daerah mengenai Retribusi
                                    Persetujuan Bangunan Gedung dan
                                  </li>
                                  <li>
                                    Peraturan kepala daerah mengenai tata cara
                                    penyelenggaraan PBG dan Sertifikat Laik
                                    Fungsi (SLF) melalui Sistem Informasi
                                    Manajemen Bangunan Gedung (SIMBG)
                                  </li>
                                </ol>

                                <p>Untuk penetapan bobot penilaian:</p>

                                <ol style="margin-left: 11px">
                                  <li>
                                    Nilai &ldquo;Ada, lebih dari 1 kali&rdquo;
                                    apabila kelima ketentuan di atas terpenuhi.
                                  </li>
                                  <li>
                                    Nilai &ldquo;Ada 1 kali&rdquo; apabila salah
                                    satu dari ketentuan di atas tidak terpenuhi.
                                  </li>
                                  <li>
                                    Nilai &ldquo;Tidak ada&rdquo; apabila
                                    seluruh ketentuan di atas tidak terpenuhi.
                                  </li>
                                </ol>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5" style="background-color: #ebedf3">
                              <b>Monitoring dan Evaluasi</b>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">1</td>
                            <td rowspan="7">
                              Apakah ada kegiatan monitoring dan evaluasi
                              kegiatan penyederhanaan persyaratan dasar
                              Perizinan Berusaha?
                            </td>
                            <td colspan="3" style="text-align: justify">
                              Penilaian ada tidaknya kegiatan monitoring dan
                              evaluasi kegiatan penyederhanaan persyaratan dasar
                              Perizinan Berusaha dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2e1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Kegiatan monitoring dan evaluasi dilakukan
                                  sekurang-kurangnya 1 kali dalam 3 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2e1_file1)"
                                v-if="pm.p2e1_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2e1_file1_status == 'Sesuai'"
                                v-model="pm.p2e1_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2e1_file1_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2e1_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2e1_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2e1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2e1_file2)"
                                v-if="pm.p2e1_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2e1_file2_status == 'Sesuai'"
                                v-model="pm.p2e1_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2e1_file2_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2e1_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2e1_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2e1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2e1_file3)"
                                v-if="pm.p2e1_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2e1_file3_status == 'Sesuai'"
                                v-model="pm.p2e1_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2e1_file3_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2e1_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2e1_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2e1_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat notula sosialisasi
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2e1_file4)"
                                v-if="pm.p2e1_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2e1_file4_status == 'Sesuai'"
                                v-model="pm.p2e1_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2e1_file4_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2e1_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2e1_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2e1_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2e1_file5)"
                                v-if="pm.p2e1_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2e1_file5_status == 'Sesuai'"
                                v-model="pm.p2e1_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2e1_file5_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2e1_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2e1_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div
                                  class="card-body text-green"
                                  style="color: white; text-align: justify"
                                >
                                  <p>Informasi</p>
                                  <p>
                                    Penyusunan Perda dan Perkada berdasarkan PP
                                    No. 6 Tahun 2021 tentang Penyelenggaraan
                                    Perizinan Berusaha di Daerah, PP No. 22
                                    Tahun 2021 tentang Penyelenggaraan
                                    Perlindungan dan Pengelolaan Lingkungan
                                    Hidup, dan PP No. 16 Tahun 2021 tentang
                                    Peraturan Pelaksanaan Undang-Undang Nomor 28
                                    Tahun 2002 Tentang Bangunan Gedung, Pemda
                                    wajib melakukan kegiatan monitoring dan
                                    evaluasi kegiatan penyederhanaan persyaratan
                                    dasar Perizinan Berusaha yakni:
                                  </p>

                                  <ol style="margin-left: 11px">
                                    <li>
                                      Perda mengenai Rencana Tata Ruang Wilayah
                                      (RTRW)
                                    </li>
                                    <li>
                                      Perkada mengenai Rencana Detail Tata Ruang
                                      (RDTR)
                                    </li>
                                    <li>
                                      Perkada mengenai pendelegasian kewenangan
                                      penyelenggaraan perizinan berusaha kepada
                                      Kepala DPMPTSP
                                    </li>
                                    <li>
                                      Perkada mengenai pendelegasian kewenangan
                                      penyelenggaraan persetujuan lingkungan
                                      kepada Kepala DPMPTSP atau Kepala Dinas
                                      Lingkungan Hidup
                                    </li>
                                    <li>Perda mengenai retribusi PBG</li>
                                    <li>
                                      Perkada mengenai tata cara penyelenggaraan
                                      PBG dan Sertifikat Laik Fungsi (SLF)
                                      melalui Sistem Informasi Manajemen
                                      Bangunan Gedung (SIMBG)
                                    </li>
                                  </ol>

                                  <p>Untuk penetapan bobot penilaian:</p>

                                  <ol style="margin-left: 11px">
                                    <li>
                                      Nilai &ldquo;Ada, teragenda&rdquo; apabila
                                      kelima ketentuan di atas terpenuhi
                                    </li>
                                    <li>
                                      Nilai &ldquo;Ada, tidak teragenda&rdquo;
                                      apabila salah satu dari ketentuan di atas
                                      tidak terpenuhi.
                                    </li>
                                    <li>
                                      Nilai &ldquo;Tidak ada&rdquo; apabila
                                      seluruh ketentuan di atas tidak terpenuhi.
                                    </li>
                                  </ol>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="2">2</td>
                            <td rowspan="2">
                              Apakah ada laporan kinerja penyederhanaan
                              persyaratan dasar Perizinan Berusaha?
                            </td>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p2e2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11" style="text-align: justify">
                                  Penilaian ada tidaknya laporan kinerja
                                  penyederhanaan persyaratan dasar Perizinan
                                  Berusaha dilakukan dengan ketentuan apabila
                                  laporan kinerja penyederhanaan persyaratan
                                  dasar Perizinan Berusaha dibuat secara formal
                                  dalam bentuk dokumen laporan lengkap fisik
                                  atau digital, bukan bahan presentasi dan
                                  sejenisnya
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p2e2_file1)"
                                v-if="pm.p2e2_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p2e2_file1_status == 'Sesuai'"
                                v-model="pm.p2e2_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p2e2_file1_status == 'Tidak sesuai'
                                "
                                v-model="pm.p2e2_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p2e2_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada” apabila ketentuan di atas
                                    terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila ketentuan di
                                    atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div v-if="pm.catatan_2_surveyor != null">
                        <div class="card text-white bg-primary mb-3">
                          <!-- <div class="card-header">Header</div> -->
                          <div class="card-body">
                            <h5 class="card-title">
                              <i
                                class="fa fa-info-circle"
                                style="color: white"
                                aria-hidden="true"
                              ></i>
                              Catatan
                            </h5>
                            <hr />
                            <div
                              class="card-text"
                              v-html="pm.catatan_2_surveyor"
                            ></div>
                            <!-- <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end: Wizard Step 2-->

                  <!--begin: Wizard Step 3-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <div
                      style="background-color: #1bc5bd"
                      class="card card-custom"
                    >
                      <div v-if="pm.catatan_3_surveyor != null">
                        <b-alert show variant="danger"
                          >Terdapat catatan, silahkan lihat catatan
                          dibawah!</b-alert
                        >
                      </div>
                      <div class="card-body text-green">
                        <h3 class="card-label" style="color: white">
                          <i
                            class="fa fa-info-circle"
                            style="color: white"
                            aria-hidden="true"
                          ></i>
                          Penting!
                        </h3>
                        <hr />
                        <p style="color: white">
                          Input data yang berkaitan dengan Peningkatan Iklim
                          Investasi berdasarkan fakta yang ada dengan memilih
                          data yang sesuai. Dokumen lampiran yang diunggah
                          adalah
                          <strong style="color: yellow">halaman depan</strong>
                          dan
                          <strong style="color: yellow"
                            >halaman pengesahan</strong
                          >
                          dokumen SOP, peraturan, dan lainnya. Ukuran file
                          setiap dokumen maksimal
                          <strong style="color: yellow">500KB</strong> dengan
                          format file
                          <strong style="color: yellow">*.pdf</strong>.
                        </p>
                      </div>
                    </div>
                    <br />
                    <div class="table-responsive-sm">
                      <table class="table">
                        <thead>
                          <tr class="table-active">
                            <th style="display: none">No</th>
                            <th style="display: none">Indikator</th>
                            <!-- <th style="display:none;">Status</th> -->
                            <th style="display: none">Jawaban</th>
                            <th style="display: none">Download</th>
                            <th style="display: none">Hasil</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="5" style="background-color: #ebedf3">
                              <b>Perangkat Pelaksana PPB</b>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="4">1</td>
                            <td rowspan="4">
                              Apakah ada tim atau unit pelaksana PPB?
                            </td>
                            <td colspan="3">
                              Penilaian ketersediaan tim atau unit pelaksana PPB
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p3a1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Pemda memiliki dan dapat menunjukkan
                                  penetapan/keputusan dari Kepala Daerah
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3a1_file1)"
                                v-if="pm.p3a1_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3a1_file1_status == 'Sesuai'"
                                v-model="pm.p3a1_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3a1_file1_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3a1_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3a1_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p3a1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Pemda memiliki ruang sekretariat organisasi
                                  tim internal Pemda yang melaksanakan tugas dan
                                  fungsi PPB
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3a1_file2)"
                                v-if="pm.p3a1_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3a1_file2_status == 'Sesuai'"
                                v-model="pm.p3a1_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3a1_file2_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3a1_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3a1_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada” apabila kedua ketentuan di
                                    atas terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila salah satu
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="4">2</td>
                            <td rowspan="4">
                              Apakah ada program kerja PPB di daerah?
                            </td>
                            <td colspan="3">
                              Penilaian program kerja PPB di daerah dilakukan
                              dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p3a2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Pemda memiliki dan dapat menunjukkan bukti
                                  program kerja
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3a2_file1)"
                                v-if="pm.p3a2_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3a2_file1_status == 'Sesuai'"
                                v-model="pm.p3a2_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3a2_file1_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3a2_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3a2_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p3a2_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Pemda memiliki dan dapat menunjukkan bukti
                                  realisasi kegiatan program kerja
                                  (sekurang-kurangnya 50% dari program kerja
                                  yang direncanakan)
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3a2_file2)"
                                v-if="pm.p3a2_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3a2_file2_status == 'Sesuai'"
                                v-model="pm.p3a2_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3a2_file2_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3a2_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3a2_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada” apabila kedua ketentuan di
                                    atas terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila salah satu
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">3</td>
                            <td rowspan="7">
                              Apakah ada rapat koordinasi unit pelaksana PPB?
                            </td>
                            <td colspan="3">
                              Penilaian ada tidaknya rapat koordinasi teragenda
                              dilakukan dengan ketentuan
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p3a3_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Rapat koordinasi dilakukan sekurang-kurangnya
                                  1 kali dalam 1 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3a3_file1)"
                                v-if="pm.p3a3_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3a3_file1_status == 'Sesuai'"
                                v-model="pm.p3a3_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3a3_file1_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3a3_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3a3_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p3a3_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3a3_file2)"
                                v-if="pm.p3a3_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3a3_file2_status == 'Sesuai'"
                                v-model="pm.p3a3_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3a3_file2_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3a3_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3a3_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p3a3_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">Terdapat daftar hadir</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3a3_file3)"
                                v-if="pm.p3a3_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3a3_file3_status == 'Sesuai'"
                                v-model="pm.p3a3_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3a3_file3_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3a3_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3a3_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p3a3_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3a3_file4)"
                                v-if="pm.p3a3_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3a3_file4_status == 'Sesuai'"
                                v-model="pm.p3a3_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3a3_file4_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3a3_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3a3_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p3a3_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3a3_file5)"
                                v-if="pm.p3a3_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3a3_file5_status == 'Sesuai'"
                                v-model="pm.p3a3_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3a3_file5_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3a3_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3a3_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, teragenda” apabila kelima
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, tidak teragenda” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi. <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="5" style="background-color: #ebedf3">
                              <b
                                >Koordinasi Formal Lintas Pemangku
                                kepentingan</b
                              >
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">1</td>
                            <td rowspan="7">
                              Apakah ada koordinasi formal dengan Pusat?
                            </td>
                            <td colspan="3">
                              Penilaian ada tidaknya koordinasi formal dengan
                              Pusat dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Rapat koordinasi dilakukan sekurang-kurangnya
                                  1 kali dalam 6 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b1_file1)"
                                v-if="pm.p3b1_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3b1_file1_status == 'Sesuai'"
                                v-model="pm.p3b1_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3b1_file1_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3b1_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3b1_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b1_file2)"
                                v-if="pm.p3b1_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3b1_file2_status == 'Sesuai'"
                                v-model="pm.p3b1_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3b1_file2_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3b1_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3b1_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">Terdapat daftar hadir</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b1_file3)"
                                v-if="pm.p3b1_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3b1_file3_status == 'Sesuai'"
                                v-model="pm.p3b1_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3b1_file3_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3b1_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3b1_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b1_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b1_file4)"
                                v-if="pm.p3b1_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3b1_file4_status == 'Sesuai'"
                                v-model="pm.p3b1_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3b1_file4_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3b1_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3b1_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b1_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b1_file5)"
                                v-if="pm.p3b1_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3b1_file5_status == 'Sesuai'"
                                v-model="pm.p3b1_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3b1_file5_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3b1_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3b1_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada 1 kali” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="7">2</td>
                            <td rowspan="7">
                              Apakah ada koordinasi formal dengan Pemerintah
                              Provinsi?
                            </td>
                            <td colspan="3">
                              Penilaian ada tidaknya koordinasi formal dengan
                              Provinsi dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Rapat koordinasi dilakukan sekurang-kurangnya
                                  1 kali dalam 3 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b2_file1)"
                                v-if="pm.p3b2_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3b2_file1_status == 'Sesuai'"
                                v-model="pm.p3b2_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3b2_file1_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3b2_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3b2_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b2_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b2_file2)"
                                v-if="pm.p3b2_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3b2_file2_status == 'Sesuai'"
                                v-model="pm.p3b2_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3b2_file2_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3b2_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3b2_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b2_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">Terdapat daftar hadir</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b2_file3)"
                                v-if="pm.p3b2_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3b2_file3_status == 'Sesuai'"
                                v-model="pm.p3b2_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3b2_file3_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3b2_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3b2_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b2_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b2_file4)"
                                v-if="pm.p3b2_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3b2_file4_status == 'Sesuai'"
                                v-model="pm.p3b2_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3b2_file4_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3b2_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3b2_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b2_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b2_file5)"
                                v-if="pm.p3b2_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3b2_file5_status == 'Sesuai'"
                                v-model="pm.p3b2_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3b2_file5_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3b2_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3b2_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada 1 kali” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="7">3</td>
                            <td rowspan="7">
                              Apakah ada koordinasi formal dengan daerah
                              lainnya?
                            </td>
                            <td colspan="3">
                              Penilaian ada tidaknya koordinasi formal dengan
                              daerah lainnya dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b3_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Rapat koordinasi dilakukan sekurang-kurangnya
                                  1 kali dalam 3 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b3_file1)"
                                v-if="pm.p3b3_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3b3_file1_status == 'Sesuai'"
                                v-model="pm.p3b3_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3b3_file1_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3b3_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3b3_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b3_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b3_file2)"
                                v-if="pm.p3b3_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3b3_file2_status == 'Sesuai'"
                                v-model="pm.p3b3_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3b3_file2_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3b3_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3b3_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b3_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">Terdapat daftar hadir</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b3_file3)"
                                v-if="pm.p3b3_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3b3_file3_status == 'Sesuai'"
                                v-model="pm.p3b3_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3b3_file3_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3b3_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3b3_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b3_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b3_file4)"
                                v-if="pm.p3b3_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3b3_file4_status == 'Sesuai'"
                                v-model="pm.p3b3_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3b3_file4_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3b3_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3b3_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b3_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b3_file5)"
                                v-if="pm.p3b3_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3b3_file5_status == 'Sesuai'"
                                v-model="pm.p3b3_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3b3_file5_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3b3_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3b3_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, teragenda” apabila kelima
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, tidak teragenda” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi. <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="7">4</td>
                            <td rowspan="7">
                              Apakah ada koordinasi formal lintas OPD?
                            </td>
                            <td colspan="3">
                              Penilaian ada tidaknya koordinasi formal lintas
                              OPD dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b4_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Rapat koordinasi dilakukan sekurang-kurangnya
                                  1 kali dalam 3 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b4_file1)"
                                v-if="pm.p3b4_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3b4_file1_status == 'Sesuai'"
                                v-model="pm.p3b4_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3b4_file1_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3b4_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3b4_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b4_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b4_file2)"
                                v-if="pm.p3b4_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3b4_file2_status == 'Sesuai'"
                                v-model="pm.p3b4_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3b4_file2_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3b4_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3b4_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b4_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">Terdapat daftar hadir</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b4_file3)"
                                v-if="pm.p3b4_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3b4_file3_status == 'Sesuai'"
                                v-model="pm.p3b4_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3b4_file3_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3b4_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3b4_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b4_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b4_file4)"
                                v-if="pm.p3b4_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3b4_file4_status == 'Sesuai'"
                                v-model="pm.p3b4_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3b4_file4_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3b4_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3b4_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b4_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b4_file5)"
                                v-if="pm.p3b4_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3b4_file5_status == 'Sesuai'"
                                v-model="pm.p3b4_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3b4_file5_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3b4_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3b4_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, teragenda” apabila kelima
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, tidak teragenda” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi. <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="7">5</td>
                            <td rowspan="7">
                              Apakah ada koordinasi pemangku kepentingan lainnya
                              (Perbankan dan APH)?
                            </td>
                            <td colspan="3">
                              Penilaian ada tidaknya koordinasi pemangku
                              kepentingan lainnya (Perbankan dan APH) dilakukan
                              dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b5_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Rapat koordinasi dilakukan sekurang-kurangnya
                                  1 kali dalam 3 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b5_file1)"
                                v-if="pm.p3b5_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3b5_file1_status == 'Sesuai'"
                                v-model="pm.p3b5_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3b5_file1_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3b5_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3b5_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b5_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b5_file2)"
                                v-if="pm.p3b5_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3b5_file2_status == 'Sesuai'"
                                v-model="pm.p3b5_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3b5_file2_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3b5_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3b5_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b5_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">Terdapat daftar hadir</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b5_file3)"
                                v-if="pm.p3b5_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3b5_file3_status == 'Sesuai'"
                                v-model="pm.p3b5_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3b5_file3_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3b5_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3b5_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b5_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b5_file4)"
                                v-if="pm.p3b5_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3b5_file4_status == 'Sesuai'"
                                v-model="pm.p3b5_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3b5_file4_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3b5_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3b5_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b5_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b5_file5)"
                                v-if="pm.p3b5_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3b5_file5_status == 'Sesuai'"
                                v-model="pm.p3b5_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3b5_file5_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3b5_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3b5_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, teragenda” apabila kelima
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, tidak teragenda” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi. <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="5" style="background-color: #ebedf3">
                              <b>Monitoring dan Evaluasi</b>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">1</td>
                            <td rowspan="7">
                              Apakah ada kegiatan monitoring dan evaluasi
                              implementasi PPB?
                            </td>
                            <td colspan="3">
                              Penilaian ada tidaknya kegiatan monitoring dan
                              evaluasi implementasi PPB dilakukan dengan
                              ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3c1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Kegiatan monitoring dan evaluasi dilakukan
                                  sekurang-kurangnya 1 kali dalam 3 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3c1_file1)"
                                v-if="pm.p3c1_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3c1_file1_status == 'Sesuai'"
                                v-model="pm.p3c1_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3c1_file1_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3c1_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3c1_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3c1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3c1_file2)"
                                v-if="pm.p3c1_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3c1_file2_status == 'Sesuai'"
                                v-model="pm.p3c1_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3c1_file2_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3c1_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3c1_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3c1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3c1_file3)"
                                v-if="pm.p3c1_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3c1_file3_status == 'Sesuai'"
                                v-model="pm.p3c1_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3c1_file3_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3c1_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3c1_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3c1_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat notula sosialisasi
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3c1_file4)"
                                v-if="pm.p3c1_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3c1_file4_status == 'Sesuai'"
                                v-model="pm.p3c1_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3c1_file4_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3c1_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3c1_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3c1_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3c1_file5)"
                                v-if="pm.p3c1_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3c1_file5_status == 'Sesuai'"
                                v-model="pm.p3c1_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3c1_file5_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3c1_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3c1_file5_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, teragenda” apabila kelima
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, tidak teragenda” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi. <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="2">2</td>
                            <td rowspan="2">
                              Apakah ada tindak lanjut dan laporan hasil
                              monitoring dan evaluasi kegiatan PPB?
                            </td>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p3c2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11" style="text-align: justify">
                                  Penilaian keberadaan tindak lanjut dan laporan
                                  hasil monitoring dan evaluasi kegiatan PPB
                                  dilakukan dengan ketentuan apabila laporan
                                  hasil monitoring dan evaluasi kegiatan PPB
                                  dibuat secara formal dalam bentuk dokumen
                                  laporan lengkap fisik atau digital, bukan
                                  bahan presentasi dan sejenisnya
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3c2_file1)"
                                v-if="pm.p3c2_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3c2_file1_status == 'Sesuai'"
                                v-model="pm.p3c2_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3c2_file1_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3c2_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3c2_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada” apabila ketentuan di atas
                                    terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila ketentuan di
                                    atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="5" style="background-color: #ebedf3">
                              <b>Tindak lanjut atas hasil Evaluasi</b>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="6">1</td>
                            <td rowspan="6">
                              Apakah ada evaluasi hasil kinerja PPB tahun
                              sebelumnya?
                            </td>
                            <td colspan="3">
                              Penilaian ada tidaknya evaluasi hasil kinerja PPB
                              tahun sebelumnya dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3d1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Tersedia surat undangan resmi evaluasi hasil
                                  kinerja PPB tahun sebelumnya (melibatkan pihak
                                  eksternal) dalam bentuk fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3d1_file1)"
                                v-if="pm.p3d1_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3d1_file1_status == 'Sesuai'"
                                v-model="pm.p3d1_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3d1_file1_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3d1_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3d1_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3d1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Tersedia daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3d1_file2)"
                                v-if="pm.p3d1_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3d1_file2_status == 'Sesuai'"
                                v-model="pm.p3d1_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3d1_file2_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3d1_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3d1_file2_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3d1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">Tersedia notula rapat</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3d1_file3)"
                                v-if="pm.p3d1_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3d1_file3_status == 'Sesuai'"
                                v-model="pm.p3d1_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3d1_file3_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3d1_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3d1_file3_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3d1_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Tersedia dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3d1_file4)"
                                v-if="pm.p3d1_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3d1_file4_status == 'Sesuai'"
                                v-model="pm.p3d1_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3d1_file4_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3d1_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3d1_file4_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada” apabila keempat ketentuan di
                                    atas terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila salah satu dari
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="2">2</td>
                            <td rowspan="2">
                              Apakah ada penambahan alokasi anggaran (rutin)
                              pelayanan perizinan berusaha?
                            </td>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p3d2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11" style="text-align: justify">
                                  Penilaian ada tidaknya penambahan alokasi
                                  anggaran (rutin) pelayanan perizinan berusaha
                                  dilakukan dengan ketentuan apabila ada
                                  penambahan alokasi anggaran (rutin) pelayanan
                                  perizinan berusaha
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3d2_file1)"
                                v-if="pm.p3d2_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                            <td>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: green;
                                  color: white;
                                "
                                v-if="pm.p3d2_file1_status == 'Sesuai'"
                                v-model="pm.p3d2_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="
                                  width: 160px;
                                  background-color: red;
                                  color: white;
                                "
                                v-else-if="
                                  pm.p3d2_file1_status == 'Tidak sesuai'
                                "
                                v-model="pm.p3d2_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                              <b-form-select
                                disabled
                                style="width: 160px"
                                v-else
                                v-model="pm.p3d2_file1_status"
                                :options="optionsPilihan"
                              ></b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Ada” apabila ketentuan di atas
                                    terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila ketentuan di
                                    atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div v-if="pm.catatan_3_surveyor != null">
                        <div class="card text-white bg-primary mb-3">
                          <!-- <div class="card-header">Header</div> -->
                          <div class="card-body">
                            <h5 class="card-title">
                              <i
                                class="fa fa-info-circle"
                                style="color: white"
                                aria-hidden="true"
                              ></i>
                              Catatan
                            </h5>
                            <hr />
                            <div
                              class="card-text"
                              v-html="pm.catatan_3_surveyor"
                            ></div>
                            <!-- <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        style="background-color: #f4a902"
                        class="card card-custom"
                      >
                        <div class="card-body text-green">
                          <h3
                            class="card-label text-green"
                            style="color: white"
                          >
                            <i
                              class="fa fa-info-circle"
                              style="color: white"
                              aria-hidden="true"
                            ></i>
                            Persetujuan
                          </h3>
                          <hr />
                          <p style="color: white; font-size: 15px">
                            Kami dari pihak PPB Pemerintah Daerah menerima dan
                            menyetujui hasil verifikasi dan validasi penilaian
                            mandiri yang telah dilaksanakan oleh surveyor dengan
                            didampingi oleh petugas kami. Seluruh hasil
                            verifikasi dan validasi penilaian mandiri telah
                            dicatat dan disimpan dalam Sistem Teknologi
                            Informasi dan Komunikasi.
                          </p>

                          <div v-if="pm.file_ba == null || pm.file_ba == ''">
                            <button
                              v-if="
                                profil.nama_kepala == null ||
                                profil.nama_kepala == ''
                              "
                              type="button"
                              style="
                                float: right;
                                background-color: #004d00;
                                border-color: #004d00;
                              "
                              @click="cekketua"
                              class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
                            >
                              <i
                                class="fa fa-check"
                                style="color: white"
                                aria-hidden="true"
                              ></i>
                              Persetujuan
                            </button>
                            <button
                              v-else-if="pm.nama_surveyor == null"
                              type="button"
                              style="
                                float: right;
                                background-color: #004d00;
                                border-color: #004d00;
                              "
                              hidden
                              class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
                            >
                              <i
                                class="fa fa-check"
                                style="color: white"
                                aria-hidden="true"
                              ></i>
                              Persetujuan
                            </button>
                            <button
                              v-else
                              type="button"
                              style="
                                float: right;
                                background-color: #004d00;
                                border-color: #004d00;
                              "
                              data-toggle="modal"
                              data-target="#exampleModal"
                              class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
                            >
                              <i
                                class="fa fa-check"
                                style="color: white"
                                aria-hidden="true"
                              ></i>
                              Persetujuan
                            </button>
                          </div>
                          <div v-else>
                            <a
                              :href="pm.file_ba"
                              target="_blank"
                              class="btn btn-primary"
                              style="
                                float: right;
                                background-color: #004d00;
                                border-color: #004d00;
                              "
                              ><i
                                class="fa fa-download"
                                aria-hidden="true"
                              />Unduh File BA</a
                            >
                            <!-- <button v-if="profil.nama_kepala == null || profil.nama_kepala == ''" type="button" style="float: right; background-color: #004d00; border-color: #004d00; margin-right:10px;" @click="cekketua" hidden class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4">Persetujuan</button>
                                <button v-else-if="vf.nama_surveyor == null" type="button" style="float: right; background-color: #004d00; border-color: #004d00; margin-right:10px;" hidden class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4">Persetujuan</button>
                                <button v-else type="button" style="float: right; background-color: #004d00; border-color: #004d00; margin-right:10px;" data-toggle="modal" data-target="#exampleModal" class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4">Persetujuan</button> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end: Wizard Step 3-->

                  <!--begin: Wizard Actions -->
                  <div class="d-flex justify-content-between border-top pt-10">
                    <div class="mr-2">
                      <button
                        class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-prev"
                      >
                        Sebelumnya
                      </button>
                    </div>
                    <div>
                      <button
                        v-on:click="submit"
                        class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-submit"
                      >
                        Selesai
                      </button>
                      <button
                        class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-next"
                      >
                        Selanjutnya
                      </button>
                    </div>
                  </div>
                  <!--end: Wizard Actions -->
                </form>
                <!--end: Wizard Form-->
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Bpdy-->
      </div>
      <!--end: Wizard-->
    </div>

    <!-- modal persetujuan -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Persetujuan</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">Setuju</label>
              <div class="col-lg-6">
                <b-form-radio
                  id="setuju"
                  v-model="pm.persetujuan_ptsp"
                  name="some-radios"
                  value="Ya"
                  >Ya</b-form-radio
                >
                <b-form-radio
                  id="tidaksetuju"
                  v-model="pm.persetujuan_ptsp"
                  name="some-radios"
                  value="Tidak"
                  >Tidak</b-form-radio
                >
              </div>
            </div>
            <div id="alasan" class="form-group row">
              <label class="col-lg-4 col-form-label">Alasan</label>
              <div class="col-lg-8">
                <textarea
                  class="form-control"
                  v-model="pm.alasan"
                  id="exampleFormControlTextarea1"
                  rows="3"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Tutup
            </button>
            <button
              type="submit"
              @click="persetujuanptsp"
              data-dismiss="modal"
              class="btn btn-primary background-color: #030A8C; border-color: #030A8C"
            >
              Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-7.scss";
</style>
  
  
  <script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import axios from "axios";
import VueLoading from "vuejs-loading-plugin";
import Vue from "vue";

Vue.use(VueLoading);

// overwrite defaults
Vue.use(VueLoading, {
  dark: true, // default false
  text: "Loading", // default 'Loading'
  loading: true, // default false
  //customLoader: myVueComponent, // replaces the spinner and text with your own
  background: "rgb(255,255,255)", // set custom background
  classes: ["myclass"], // array, object or string
});

export default {
  name: "Wizard-4",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "PPB Pemda" }]);

    // Initialize form wizard
    const wizard = new KTWizard("kt_wizard_v4", {
      startStep: 1, // initial active step number
      clickableSteps: true, // allow step clicking
    });

    // Validation before going to next page
    wizard.on("beforeNext", function (/*wizardObj*/) {
      // validate the form and use below function to stop the wizard's step
      // wizardObj.stop();
    });

    // Change event
    wizard.on("change", function (/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });

    this.loadData();

    $(document).ready(function () {
      $("#tidaksetuju").click(function () {
        if ($(this).is(":checked")) {
          $("#alasan").show();
          $("#next").hide();
        } else {
          $("#alasan").hide();
          $("#next").show();
        }
      });
      $("#setuju").click(function () {
        if ($(this).is(":checked")) {
          $("#alasan").hide();
          $("#next").show();
        } else {
          $("#alasan").show();
          $("#next").hide();
        }
      });
    });
  },
  props: {
    user: {
      type: Array,
      default: () => {
        return [];
      },
    },
    url: String,
    token: String,
    idppb: String,
    idptsp: String,
    idprofil: String,
    tahun: String,
  },
  data() {
    return {
      bagian: [
        { value: "kadis", text: "Kepala Dinas" },
        { value: "kabidpb", text: "Kepala Bidang Perizinan Berusaha" },
        { value: "kasiepb", text: "Kepala Seksi Bidang Perizinan Berusaha" },
        {
          value: "foffpb",
          text: "Staf Front-office Bidang Perizinan Berusaha",
        },
        { value: "boffpb", text: "Staf Back-office Bidang Perizinan Berusaha" },
        { value: "hdeskpb", text: "Staf Help Desk Bidang Perizinan Berusaha" },
        { value: "kabiddal", text: "Kepala Bidang Dalaks" },
        { value: "kasiedal", text: "Kepala Seksi Bidang Dalaks" },
        { value: "foffdal", text: "Staf Front-office Bidang Dalaks" },
        { value: "boffdal", text: "Staf Back-office Bidang Dalaks" },
        { value: "hdeskdal", text: "Staf Help Desk Bidang Dalaks" },
      ],
      pm: [],
      profil: [],
      selectedFile: null,
      optionsPilihan: [
        { value: "Belum diverifikasi", text: "Belum diverifikasi" },
        { value: "Sesuai", text: "Sesuai" },
        { value: "Tidak sesuai", text: "Tidak sesuai" },
      ],
    };
  },
  methods: {
    submit: function (e) {
      e.preventDefault();
      Swal.fire({
        title: "",
        text: "Pengisian telah selesai",
        icon: "success",
        confirmButtonClass: "btn btn-secondary",
      });
      this.$router.push({ name: "dashboard" });
    },

    unduhFile(data) {
      window.open(data, "_blank");
    },
    exportExcel(data) {
      window.open(
        this.url + `/export/ppb_pemda/` + this.user.id_ptsp + `/` + data,
        "_blank"
      );
    },
    cekketua() {
      Swal.fire({
        title: "",
        text: "Mohon maaf Anda tidak dapat melakukan persetujuan. Pastikan Nama Kepala PPB Pemda di dalam profil telah diisi",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
      });
    },

    persetujuanptsp() {
      if (this.pm.persetujuan_ptsp == null) {
        this.$bvToast.toast("Persetujuan gagal, pastikan data terisi", {
          title: `Failed `,
          variant: `danger`,
          solid: true,
        });
      } else {
        if (this.pm.persetujuan_ptsp === "Ya") {
          this.pm.alasan = null;
        }
        axios
          .put(
            this.url + "/verifikasi_ppb_pemda_2023_persetujuan/" + this.pm.id,
            {
              _method: "patch",
              persetujuan_ptsp: this.pm.persetujuan_ptsp,
              alasan: this.pm.alasan,
            },
            {
              headers: {
                xth: this.token,
              },
            }
          )
          .then((response) => {
            this.$bvToast.toast("Persetujuan berhasil", {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            this.loadData();
            return response;
          })
          .catch((error) => {
            this.$bvToast.toast("Persetujuan gagal", {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            console.log(error);
            return error;
          });
      }
    },
    loadData() {
      axios
        .get(
          this.url +
            `/verifikasi_ppb_pemda_2023s?filter=idptsp,=,` +
            this.user.id_ptsp +
            ";tahun,=," +
            this.tahun,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.pm = response.data.data;
          console.log(this.pm.p1a1_file1);
        });

      axios
        .get(this.url + `/profils?filter=idptsp,=,` + this.user.id_ptsp, {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.profil = response.data.data;
        });
    },
  },
};
</script>
  
  <style scoped>
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 768px) {
  /* Force table to not be like tables anymore */
  table,
  thead,
  th,
  td,
  tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    margin: 10px 1rem 1rem 1rem;
  }

  /* tr:nth-child(odd) {
      background: #E1EDFF;
    } */

  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    /* padding-left: 50%; */
  }

  th {
    display: none;
  }

  /* td:before { */
  /* Now like a table header */
  /* position: absolute; */
  /* Top/left values mimic padding */
  /* top: 0;
			left: 6px;
			width: 45%;
			padding-right: 10px;
			white-space: nowrap; */
  /* } */

  /*
		Label the data
    You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
		*/
  /* td:nth-of-type(1):before { content: "No"; }
		td:nth-of-type(2):before { content: "Indikator"; }
		td:nth-of-type(3):before { content: "Status"; }
		td:nth-of-type(4):before { content: "Jawaban"; }
        td:nth-of-type(5):before { content: "Unggah"; } 
        td:nth-of-type(6):before { content: "Download"; }  */
}
</style>